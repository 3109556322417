.frontSalesBox {
  margin-top: 100px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
  margin-bottom: 120px;
}
.frontSalesBox .frontSalesBoxHeadOne {
  display: none;
}
.frontSalesBox .innerfrontSalesBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.frontSalesBox .innerfrontSalesBox .btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.frontSalesBox .innerfrontSalesBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.frontSalesBox .innerfrontSalesBox .btnBox button:hover {
  background-color: #1a83d8;
}
.frontSalesBox .innerfrontSalesBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.frontSalesBox .innerfrontSalesBox .frontSalesBoxHeadTwo {
  text-transform: uppercase;
  font-size: 30px;
}
.frontSalesBox .innerfrontSalesBox .searchBar {
  position: relative;
  margin-right: 10px;
}
.frontSalesBox .innerfrontSalesBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.frontSalesBox .innerfrontSalesBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.frontSalesBox .innerfrontSalesBox .searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.frontSalesBox .innerfrontSalesBox .searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}

.frontSalesViewBox {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.281);
}
.frontSalesViewBox .innerfrontSalesViewBox {
  background: white;
  border-radius: 20px;
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  border: 5px solid white;
  width: 30%;
  padding: 0 20px;
  position: relative;
}
.frontSalesViewBox .innerfrontSalesViewBox h3 {
  position: absolute;
  right: 10px;
}
.frontSalesViewBox .innerfrontSalesViewBox h3 svg {
  position: relative;
  font-size: 25px;
  color: white;
}
.frontSalesViewBox .innerfrontSalesViewBox h5 {
  font-size: 25px;
  margin-top: 10px;
  color: white;
}
.frontSalesViewBox .innerfrontSalesViewBox > div {
  overflow-y: scroll;
  height: 220px;
  padding: 0 5px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.frontSalesViewBox .innerfrontSalesViewBox > div::-webkit-scrollbar {
  width: 4px;
}
.frontSalesViewBox .innerfrontSalesViewBox > div::-webkit-scrollbar-thumb {
  background-color: #064CDB;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems {
  background: white;
  box-shadow: 3px 9px 10px rgba(128, 128, 128, 0.6);
  padding: 7px 5px;
  border-radius: 10px;
  margin: 10px 0;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems .frontSalesfileFlex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems .frontSalesfileFlex img {
  height: 40px;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems .frontSalesfileFlex > svg {
  font-size: 40px;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems .frontSalesfileFlex span {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600;
  color: grey;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems .frontSalesfileFlex h5 {
  background: rgba(128, 128, 128, 0.3215686275);
  padding: 10px;
  line-height: 0;
  border-radius: 50%;
}
.frontSalesViewBox .innerfrontSalesViewBox > div .frontSalesfileItems .frontSalesfileFlex h5 svg {
  font-size: 24px;
  color: black;
}

@media screen and (max-width: 992px) {
  .frontSalesBox .frontSalesBoxHeadOne {
    display: block;
    font-size: 23px;
    margin: 15px 0 0 6px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
  }
  .frontSalesBox .innerfrontSalesBox {
    justify-content: center;
  }
  .frontSalesBox .innerfrontSalesBox .frontSalesBoxHeadTwo {
    display: none;
  }
  .frontSalesBox .innerfrontSalesBox .searchBar {
    margin-top: 20px;
    width: 90%;
    margin-right: 0;
  }
  .frontSalesBox .innerfrontSalesBox .searchBar input {
    width: 100%;
  }
  .frontSalesViewBox .innerfrontSalesViewBox {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .frontSalesViewBox .innerfrontSalesViewBox {
    width: 70%;
  }
}
@media screen and (max-width: 420px) {
  .frontSalesBox .innerfrontSalesBox .btnBox button {
    margin: 10px 5px;
  }
  .frontSalesBox .innerfrontSalesBox .btnBox a {
    margin: 10px 5px;
  }
  .frontSalesViewBox .innerfrontSalesViewBox {
    width: 95%;
  }
}
@media screen and (max-width: 380px) {
  .frontSalesBox {
    margin-bottom: 200px !important;
  }
}/*# sourceMappingURL=FrontSales.css.map */