@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    padding: 0;
    margin: 0;
    background: #F8F8F8 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
textarea,
text,
span,div {
    font-family: 'Poppins', sans-serif !important;
}

ul {
    padding: 0 !important;
    margin: 0 !important;
}

li {
    padding: 0;
    margin: 0%;
    list-style: none;
    font-family: 'Poppins', sans-serif;
}

input {
    font-family: 'Poppins', sans-serif;
}

b {
    font-family: 'Poppins', sans-serif;
}

span {
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
}

img {
    cursor: pointer;
}

svg {
    cursor: pointer;
}

button {
    border: none;
    cursor: pointer;
}

a {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none !important;
}

a:hover {
    text-decoration: none !important;
}

p {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

input {
    outline: none;
    font-family: 'Poppins', sans-serif;
}

input:focus {
    outline: none;
}

input::placeholder {
    font-family: 'Poppins', sans-serif;
}

textarea {
    width: 100%;
}

/* PAGES CLASSES ============================= */
.allPages {
    display: flex;
    position: relative;
    width: 100%;
    overflow-x: clip;
}

.allPages .innerBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

/* GLOBAL LOADER CSS ================================== */

.loaderBox {
    border-radius: 0 0 15px 15px;
    width: 100%;
    background: #3f51b50d;
    padding: 80px 0;
}

.loaderBox span {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    color: grey;
    font-size: 25px;
}

.loader {
    width: 100px;
    height: 100px;
    margin: 40px auto 0;
    position: relative;
    animation: animate 1s infinite;
}

.loader div {
    width: 20%;
    height: 20%;
    border-radius: 50%;
    position: absolute;
    animation: speed 2s infinite ease-in-out;
}

.loader .one {
    background: #afc531;
    animation-delay: 1.5s;
}

.loader .two {
    background: #a11cc7;
    animation-delay: 1s;
}

.loader .three {
    background: #058fd4;
    animation-delay: 0.5s;
}

.loader .four {
    background: #f05151;
}

@keyframes speed {
    0% {
        border-radius: 50%;
        transform: translate(0%);
    }

    25% {
        border-radius: 0;
        transform: translate(150%) scale(0.5);
    }

    50% {
        border-radius: 50%;
        transform: translate(150%, 150%);
    }

    75% {
        border-radius: 0;
        transform: translate(0, 150%) scale(0.5);
    }
}

/* =================================================== */
.hXLGuA {
    border-radius: 0 !important;
}

.hXLGuA::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

.hXLGuA::-webkit-scrollbar {
    height: 6px;
    background-color: #F5F5F5;
}

.hXLGuA::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2196f3;
}

.dataTableBox .dataTableFlexBox {
    display: flex;
    align-items: center;
    justify-content: start;
}

.dataTableFlexBox h5 {
    margin: 0px;
}

.dataTableFlexBox h6 {
    margin: 0px;
}

.dataTableBox .dataTableFlexBox h5 img {
    height: 31px;
    display: block;
    object-fit: contain;
    margin: 0px 0;
}

.dataTableBox .dataTableFlexBox h6 {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.dataTableBox .dataTableFlexBox .tableName {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
}

.dataTableBox .dataTableFlexBox .tableEmail {
    font-size: 12px;
    color: #aba3a3;
    margin-top: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
}

.pacDetailsBox {
    display: flex;
    flex-direction: column;
}

.pacDetailsBox .package_name {
    line-height: 17px;
    white-space: break-spaces;
}

.dataTableIconBox button {
    background: transparent;
}

.dataTableIconBox button svg {
    font-size: 25px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 2px 3px;
}

.dataTableIconBox button svg.fileViewIcon {
    color: #2196F3;
}

.dataTableIconBox a svg {
    font-size: 28px;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.dataTableIconBox .updateAlert svg {
    color: #E91E63;
    font-size: 30px;
}

.dataTableIconBox .deleteAlert svg {
    color: red;
    font-size: 30px;
}

.dataTableIconBox .sentAlert svg {
    color: #5db55d;
}

.dataTableIconBox .commintBtn svg {
    color: #2196F3;
}

.dataTableIconBox .paymentBtn svg {
    color: #2196F3;
}

.dataTableIconBox .updateAlert svg {
    color: #E91E63;
}

.dataTableBox .commonOrderbtn {
    padding: 6px 7px;
    text-transform: capitalize;
    font-weight: 600;
    color: white;
    background: #2196f3;
    border-radius: 3px;
}

.dataTableIconBox a svg.OrderViewIco {
    color: #2196F3;
    padding: 2px 3px;
}

.dataTableBox img {
    height: 25px;
    display: block;
    margin: auto;
    object-fit: contain;
    margin: 10px 0;
}

.dataTableBox .profilePicture {
    height: 66px;
    width: 65px;
    border-radius: 70%;
    display: block;
    margin: auto;
    object-fit: cover;
    padding: 5px 4px;
}

.dataTableBox select {
    border: 1px solid lightgrey;
    width: 100%;
    border-radius: 10px;
    padding: 5px 10px;
}

.notFoundMsg {
    color: #8080809e;
    background: transparent !important;
}

/* ============================================= */
.askLoginBtnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.askLoginBtnBox>div {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.askLoginBtnBox>div button a {
    color: white;
}

.askLoginBtnBox>div button a:hover {
    color: white;
}

/* =============================== */
/* NOT FOUND PAGE CSS ======================== */
.notfound-404 {
    height: 100vh;
    width: 100%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notfound-404 .notfound-404-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.notfound-404 .notfound-404-inner h1 {
    font-size: 224px;
    font-weight: 900;
    margin-left: -12px;
    color: #030005;
    text-transform: uppercase;
    text-shadow: -1px -1px 0 #8400ff, 1px 1px 0 #ff005a;
    letter-spacing: -20px;
}

.notfound-404 .notfound-404-inner h2 {
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 0 #8400ff;
    letter-spacing: 13px;
    margin: 0;
    position: absolute;
    top: 59%;
}

/* SIDEBAR LINK ACTIVE CLASS */
.sideBarLinksActive.active {
    background: #1f1e1ee6;
    border-radius: 5px;
}

@media screen and (max-width: 575px) {
    .notfound-404 .notfound-404-inner h2 {
        font-size: 30px;
        text-align: center;
        top: 59%;
    }
}

@media screen and (max-width: 575px) {
    .notfound-404 .notfound-404-inner h1 {
        font-size: 150px;
    }
}

/* ============================================== */
.dashboraHead {
    font-weight: 500;
    font-size: 30px;
}

/* PAYPAL BUTTON CSS =-=-=-=-=-=-=-=-=-=-=-= */
#paypal-button-container>.paypal-buttons {
    display: block !important;
}

/* =-=-=-=-=-= */
.printTableHead tr th {
    padding: 0 4px;
}

.printTableBody tr td {
    word-break: break-all;
    margin: 21px 1px;
    padding: 0 9px;
}




/* ========================== */
.nCTcL {
    font-family: 'Poppins', sans-serif !important;
}

.ofEne {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif !important;
}

.interClass-menu {
    padding-top: 40px;
}

.sc-bOhtcR {
    min-width: 100% !important;
    background-color: transparent !important;
    padding: 20px;
    border-radius: 20px;
}

.sc-fUBkdm:focus {
    outline: none !important;
    outline-offset: 2px;
}

.sc-hBtRBD {
    background-color: white !important;
    width: 100% !important;
}
.sc-bOQTJJ::-webkit-scrollbar {
    width: 5px !important;
}

.sc-bOQTJJ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

.sc-bOQTJJ::-webkit-scrollbar-thumb {
    background: #888 !important;
}

.sc-bOQTJJ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.sc-gEkIjz {
    border: 1px solid rgb(209, 209, 209) !important;
    background-color: white;
}

.sc-fUBkdm {
    padding: 15px !important;
    margin: 0px !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px !important;
    font-weight: 600;
    background-color: white;
}

.sc-gEkIjz {
    height: 600px !important;
}
.sc-bOQTJJ{
    max-height: 547px !important;
}
.sc-hBtRBD{
    max-height: 547px !important;
}

.sc-hBtRBD {
    padding-top: 0px !important;
}

.sc-hYmls {
    margin-inline: 10px !important;
}

.sc-iLsKjm {
    border-radius: 8px !important;
    border: 1px solid #d9d9d9 !important;
    background-color: rgb(255, 255, 255);
    box-shadow: none;
    box-sizing: border-box;
    padding: 8px;
    min-height: 40px;
    margin-bottom: 8px;
    user-select: none;
    color: rgb(9, 30, 66);
    display: flex;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.ant-select-single{
    height: 40px !important;
}

.displayed-text{
    font-size: 12px;
    color: rgb(91, 91, 91);
}
.read-more-button{
    font-size: 12px;
    color: rgb(14, 114, 255);
    font-weight: 600;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon{
    color: rgb(14, 114, 255) !important;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name{
    color: rgb(14, 114, 255) !important;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-actions .anticon{
    color: rgb(14, 114, 255) !important;
}
.ant-modal{
    top: 30px !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
}
.ant-tabs .ant-tabs-tab-btn{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
}
@media (min-width: 1400px) {
    .container {
        max-width: 1420px !important;
    }
}
.ant-table{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.ant-pagination{
    padding-top: 10px !important;
}
.PhoneInputInput{
    border: 1px solid rgb(225 225 225);
    outline: none;
    border-radius: 5px;
    padding: 7px;
    background-color: white;
    font-family: 'Poppins', sans-serif !important;
}
/* .ant-modal-content{
    min-height: 640px;
} */

.sc-gEkIjz{
    width: 25%;
}
.sc-bOhtcR{
    width: 100%;
}
