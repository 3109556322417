.client_loginFormSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.client_loginFormBox .logoBox {
  background: #064CDB;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.client_loginFormBox .logoBox img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 55px;
  padding: 10px 18px;
}
.client_loginFormBox .client_pwdBoxOfLogin {
  position: relative;
}
.client_loginFormBox .client_pwdBoxOfLogin img {
  position: absolute;
  height: 18px;
  width: 18px;
  right: 11px;
  top: 44px;
}
.client_loginFormBox .client_formRememberBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.client_loginFormBox .client_formRememberBox .form-check label {
  font-weight: 600;
  color: rgba(128, 128, 128, 0.658);
}
.client_loginFormBox .client_formRememberBox a {
  color: rgba(128, 128, 128, 0.658);
}
.client_loginFormBox .client_LoginLinkBox {
  padding: 0;
  margin-top: 10px;
}
.client_loginFormBox .client_LoginLinkBox span {
  color: rgba(128, 128, 128, 0.3215686275);
}
.client_loginFormBox .client_signInBtnBox button {
  color: #fff;
  text-transform: uppercase;
  padding: 10px 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.client_loginFormBox .client_signInBtnBox button:focus {
  color: #fff;
}
.client_loginFormBox .client_signInBtnBox button:hover {
  color: #fff;
  background: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.client_loginFormBox .client_signInBtnBox button:before {
  content: "";
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.client_loginFormBox .client_signInBtnBox button:hover:before {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  transform: scale(2) rotate(180deg);
}
.client_loginFormBox .client_signInBtnBox span {
  margin-top: 10px;
  font-weight: 500;
  color: rgba(128, 128, 128, 0.658);
}
.client_loginFormBox .client_signInBtnBox span a {
  margin-left: 5px;
  color: rgba(128, 128, 128, 0.658);
  text-decoration: underline !important;
}

@media screen and (max-width: 992px) {
  .client_formImgBox {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .client_loginFormBox .client_formRememberBox {
    align-items: self-start;
    justify-content: start;
    flex-direction: column;
  }
  .client_loginFormBox .client_formRememberBox a {
    margin-top: 10px;
  }
}
@media screen and (max-width: 300px) {
  .client_loginFormSection {
    height: auto;
  }
  .client_loginFormBox .logoBox {
    padding: 10px 10px;
  }
  .client_loginFormBox .logoBox img {
    height: 45px;
    padding: 5px 10px;
  }
}/*# sourceMappingURL=ClientLogin.css.map */