
// COLORS ===========================================
@import 'colors';


// ALL ORDERS SCSS  ========================================================

.client_orderBox{
    margin-top: 100px;
    box-shadow: 3px 6px 10px $shadowColor;
    border-radius: 18px;
    border: 2px solid $borderColor;
    margin-bottom: 120px;
    .client_orderBoxHeadOne{
        display: none;
    }

    .client_addOrdersBtn{
        background: $lightBlue;
        color: $white;
        margin-top: 30px;
        margin-left: 5px;
        padding: 10px 30px;
        border-radius: 5px;
        letter-spacing: 1px;
    }

    .client_innerOrderBox{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        border-bottom: 2px solid $borderColor;
        padding-bottom: 10px;
        margin-bottom: 20px;
        .client_btnBox{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            button{
                padding: 5px 10px;
                margin: 0 5px;
                height: 35px;
                border-radius: 5px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
            a{
                border-radius: 5px;
                margin: 0 5px;
                padding: 5px 10px;
                height: 35px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
        }
        .client_orderBoxHeadTwo{
            text-transform: uppercase;
            font-size: 30px;
        }
        .client_searchBar {
            position: relative;
            margin-right: 10px;
            svg {
                position: absolute;
                left: 6px;
                top: 12px;
                font-size: 22px;
                color: $lightgreycolor;
            }
            input {
                letter-spacing: 1px;
                border: none;
                background: $inputBg;
                border-radius: 5px;
                padding: 10px 17px 10px 30px;
                &::placeholder {
                    color: $greyTextColor;
                    font-size: 14px;
                }
            }
        }
    }
}

// ONE MONTH ORDER SCSS  ===============================================


.client_oneMonthOrderBox{
    margin-top: 20px;
    box-shadow: 3px 6px 10px $shadowColor;
    border-radius: 18px;
    border: 2px solid $borderColor;
    margin-bottom: 120px;

    .desktopViewHead{
        display: block;
        font-size: 23px;
        margin: 15px 0 0 6px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }
    .MobileViewHead{
        display: none;
        font-size: 23px;
        margin: 15px 0 0 6px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    .client_innerOrderBox{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        border-bottom: 2px solid $borderColor;
        padding-bottom: 10px;
        margin-bottom: 20px;
        .client_btnBox{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            button{
                padding: 5px 10px;
                height: 35px;
                margin: 0 5px;
                border-radius: 5px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
            a{
                border-radius: 5px;
                padding: 5px 10px;
                height: 35px;
                margin: 0 5px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
        }
        .client_searchBar {
            position: relative;
            margin-right: 10px;
            svg {
                position: absolute;
                left: 6px;
                top: 12px;
                font-size: 22px;
                color: $lightgreycolor;
            }
            input {
                letter-spacing: 1px;
                border: none;
                background: $inputBg;
                border-radius: 5px;
                padding: 10px 17px 10px 30px;
                &::placeholder {
                    color: $greyTextColor;
                    font-size: 14px;
                }
            }
        }
    }
}

// ==================================
.allOrdersDetailsTag{
    white-space: break-spaces;
    line-height: 19px;
    display: block;
}


// RESPONSIVE ==================================================
$breakpoint-tablet: 768px;

@media screen and (max-width:992px){
    .client_orderBox{
        .client_orderBoxHeadOne{
            display: block;
            font-size: 23px;
            margin: 15px 0 0 6px;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
        }
        .client_innerOrderBox{
            justify-content: center;
            .client_orderBoxHeadTwo{
                display: none;
            }
            .client_searchBar {
                margin-top: 20px;
                width: 90%;
                margin-right: 0;
                input{
                    width: 100%;
                }
            }
        }
    }
    .client_oneMonthOrderBox{
        .desktopViewHead{
            display: none;
        }
        .MobileViewHead{
            display: block;
        }
    }
}
@media (max-width: $breakpoint-tablet) {
    .client_oneMonthOrderBox{
        .client_innerOrderBox{
            justify-content: center;
            .client_searchBar {
                margin-top: 20px;
                width: 90%;
                margin-right: 0;
                input{
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width:420px){
    .client_orderBox{
        .client_innerOrderBox{
            .client_btnBox{
                button{
                    margin: 10px 5px;
                }
                a{
                    margin: 10px 5px;
                }
            }
        }
    }

    .client_oneMonthOrderBox{
        .client_innerOrderBox{
            .client_btnBox{
                button{
                    margin: 10px 5px;
                }
                a{
                    margin: 10px 5px;
                }
            }
        }
    }
}
@media screen and (max-width: 380px){
    .client_orderBox{
        margin-bottom: 200px !important
    }
    .client_oneMonthOrderBox{
        margin-bottom: 200px !important
    }
}