.client_topBarBox {
  background: white;
  box-shadow: 10px -5px 12px 6px rgba(158, 158, 158, 0.5098039216);
  padding: 10px 0;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 999;
}
.client_topBarBox span.client_barIco svg {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 26px;
  margin-left: 20px;
  position: relative;
  top: 7px;
}
.client_topBarBox .client_leftAreaBoxTopBar {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
}

.client_leftAreaBoxTopBar .client_massBox {
  position: relative;
}
.client_leftAreaBoxTopBar .client_massBox .client_massBoxIco {
  margin-left: 50px;
}
.client_leftAreaBoxTopBar .client_massBox .client_massBoxIco svg {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 30px;
  transition: all 1s;
}
.client_leftAreaBoxTopBar .client_massBox .client_massBoxIco svg:hover {
  color: #064CDB;
  transition: all 1s;
}
.client_leftAreaBoxTopBar .client_notifyBox {
  position: relative;
}
.client_leftAreaBoxTopBar .client_notifyBox .client_notifyBoxIco {
  margin-left: 30px;
  position: relative;
}
.client_leftAreaBoxTopBar .client_notifyBox .client_notifyBoxIco .client_notificateCount {
  position: relative;
  right: 8px;
  top: -14px;
  background: red;
  color: white;
  padding: 0px 5px;
  font-size: 10px;
  border-radius: 5px;
}
.client_leftAreaBoxTopBar .client_notifyBox .client_notifyBoxIco svg {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 30px;
  transition: all 1s;
}
.client_leftAreaBoxTopBar .client_notifyBox .client_notifyBoxIco svg:hover {
  color: #064CDB;
  transition: all 1s;
}

.client_notifyBox .client_notifyModal {
  position: absolute;
  width: 350px;
  box-shadow: 10px 2px 32px 6px rgba(76, 73, 73, 0.4);
  background: white;
  right: 0;
  margin-top: 40px;
  display: none;
}
.client_notifyBox .client_notifyModal .client_modalHeader {
  display: flex;
  justify-content: space-around;
  background: #ccced1;
  height: 70px;
  align-items: center;
  background-image: url(../images/ModalHeader.webp) !important;
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.3882352941);
}
.client_notifyBox .client_notifyModal .client_modalHeader h6.client_notifyCount {
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
}
.client_notifyBox .client_notifyModal .client_modalHeader button {
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
}
.client_notifyBox .client_notifyModal .client_notifyContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.client_notifyBox .client_notifyModal .client_notifyContent img {
  border-radius: 50px;
  height: 45px;
}
.client_notifyBox .client_notifyModal .client_notifyContent .client_userContent {
  margin-left: 10px;
}
.client_notifyBox .client_notifyModal .client_notifyContent .client_userContent .client_userName {
  font-weight: 500;
  font-size: 16px;
}
.client_notifyBox .client_notifyModal .client_notifyContent .client_userContent .client_userDescription {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 12px;
  margin-top: 5px;
  letter-spacing: 0.1px;
}
.client_notifyBox .client_notifyModal .client_notifyFooter {
  background: white;
  border-top: 1px solid rgba(128, 128, 128, 0.3215686275);
  text-align: center;
  padding: 11px 0;
  margin-top: 10px;
}
.client_notifyBox .client_notifyModal .client_notifyFooter a {
  color: #064CDB;
}

#client_hideNotifyModal {
  display: none;
  z-index: -1;
  transition: all 0.1s;
}

#client_showNotifyModal {
  display: block;
  transition: all 1s;
  z-index: 9999;
}

.client_userProfileBox {
  position: relative;
}
.client_userProfileBox .client_userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  border-left: 1px solid rgba(128, 128, 128, 0.3215686275);
  padding-left: 9px;
}
.client_userProfileBox .client_userInfo h5 {
  display: flex;
  flex-direction: column;
  margin: 0 15px 0px 0px;
}
.client_userProfileBox .client_userInfo h5 span.client_userName {
  font-weight: 500;
  font-size: 15px;
}
.client_userProfileBox .client_userInfo h5 span.client_userDesig {
  color: rgba(128, 128, 128, 0.3215686275);
  margin-top: 5px;
  font-size: 13px;
}
.client_userProfileBox .client_userInfo .client_userActiveBox {
  position: relative;
}
.client_userProfileBox .client_userInfo .client_userActiveBox svg {
  position: absolute;
  color: #32b432;
  left: -13px;
  top: -3px;
  font-size: 30px;
}
.client_userProfileBox .client_userInfo .client_userActiveBox img {
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  width: 50px;
}
.client_userProfileBox .client_userProfileModal {
  position: absolute;
  width: 350px;
  right: 25px;
  margin-top: 32px;
  box-shadow: 10px 2px 32px 6px rgba(76, 73, 73, 0.4);
  background: white;
  display: none;
}
.client_userProfileBox .client_userProfileModal .client_profileHeader {
  height: 70px;
  background-image: url(../images/ModalHeader.webp) !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.3882352941);
}
.client_userProfileBox .client_userProfileModal .client_profileHeader h5 {
  color: white;
  display: flex;
  flex-direction: column;
}
.client_userProfileBox .client_userProfileModal .client_profileHeader h5 span.client_userName {
  font-weight: 600;
  font-size: 13px;
}
.client_userProfileBox .client_userProfileModal .client_profileHeader h5 span.client_emailUser {
  font-size: 13px;
}
.client_userProfileBox .client_userProfileModal .client_profileHeader svg {
  transform: rotate(90deg);
  color: white;
  font-size: 25px;
}
.client_userProfileBox .client_userProfileModal .client_profileContentBox .client_innerProfileContentBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  line-height: 3;
  transition: all 1s;
}
.client_userProfileBox .client_userProfileModal .client_profileContentBox .client_innerProfileContentBox:hover span {
  color: #064CDB;
  transition: all 1s;
}
.client_userProfileBox .client_userProfileModal .client_profileContentBox .client_innerProfileContentBox svg {
  margin-right: 5px;
  font-size: 20px;
}
.client_userProfileBox .client_userProfileModal .client_profileContentBox .client_innerProfileContentBox span {
  color: #a2a5bd;
}
.client_userProfileBox .client_userProfileModal #client_profileNotificateBox {
  display: flex;
  justify-content: space-between;
}
.client_userProfileBox .client_userProfileModal #client_profileNotificateBox .client_profileNotifyCounter {
  background: #2196f3;
  color: white;
  padding: 5px 10px;
  position: relative;
  right: 20px;
  line-height: normal;
  border-radius: 50px;
}

#client_hideProfile {
  display: none;
  z-index: -1;
  transition: all 0.1s;
}

#client_showProfile {
  display: block;
  z-index: 9999;
  transition: all 1s;
}

@media screen and (max-width: 992px) {
  .client_topBarBox span.client_barIco svg {
    margin-left: 6px;
    position: relative;
    top: 3px;
  }
}
@media (max-width: 768px) {
  .client_topBarBox .client_leftAreaBoxTopBar {
    position: unset;
  }
  .client_topBarBox .client_notifyBox {
    position: unset;
  }
  .client_topBarBox .client_notifyBox .client_notifyModal {
    margin-top: 20px;
    width: 100%;
  }
  .client_topBarBox .client_userProfileBox {
    position: unset;
  }
  .client_topBarBox .client_userProfileBox .client_userProfileModal {
    margin-top: 11px;
    width: 100%;
    right: 0;
  }
}
@media screen and (max-width: 480px) {
  .client_topBarBox .client_userProfileBox .client_userInfo {
    border: 0;
  }
  .client_topBarBox .client_userProfileBox .client_userInfo h5 {
    display: none;
  }
}
@media screen and (max-width: 310px) {
  .client_topBarBox .client_userProfileBox .client_userInfo {
    margin: 0 10px;
  }
  .client_topBarBox .client_notifyBox .client_notifyBoxIco {
    margin-left: 15px;
  }
}
@media screen and (max-width: 280px) {
  .client_leftAreaBoxTopBar .client_notifyBox .client_notifyBoxIco svg {
    font-size: 23px;
  }
  .client_userProfileBox .client_userInfo {
    margin: 0 10px 0 0px !important;
  }
  .client_userProfileBox .client_userInfo .client_userActiveBox svg {
    left: -6px;
    top: 1px;
    font-size: 15px;
  }
}/*# sourceMappingURL=clienttopBar.css.map */