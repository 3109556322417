// COLORS ===========================================
@import 'colors';


.addUserModalBox{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(37, 36, 36, 0.397);
    top: 0;
    left: 0;
    z-index: 9999;

    .addUserBox{
        width: 40%;
        background: white;
        padding: 30px 40px;
        border-radius: 15px;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 98%;
            width: 98%;
            top: 5px;
            left: 5px;
            border: 3px solid lightgray;
            padding: 42px 22px;
            border-radius: 11px;
        }
        h4{
            background: $themeTxtColor;
            padding: 10px 0;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            img{
                height: 40px;
            }
        }
        .addUserScrollBox{
            height: 300px;
            overflow-y: scroll;
             // SCROLL BOX SCSS 
            &::-webkit-scrollbar-track{
                background-color: white;
            }
            &::-webkit-scrollbar{
                width: 4px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: $themeTxtColor;
        }
        }
        .inneraddUserBox{
            margin: 0 10px;
            .form-group{
                position: relative;
                img{
                    position: absolute;
                    right: 0;
                    top: 58px;
                    right: 14px;
                    height: 20px;
                }
            }
            label{
                margin-top: 15px;
            }
            select{
                height: 37px;
                position: relative;
                option{
                    text-transform: capitalize;
                }
            }
            input{
                width: 100%;
            }
        }
        .permissionCheckBoxes{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 30px;
            margin: 0 10px;
            label{
                font-weight: 600;
            }
        }
        .addUserBtnBox{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            z-index: 9999;
            position: relative;
            button:nth-child(1){
                background: #fd5d93;
                color: $white;
            }
            button{
                margin: 0 10px;
                padding: 10px 25px;
                border-radius: 5px;
                font-weight: 500;
                letter-spacing: 1px;
            }
        }
    }
}

// RESPONSIVE ====================================================================
$breakpoint-tablet-large: 992px;
$breakpoint-tablet-medium: 768px;
$breakpoint-extra-medium: 480px;
@media screen and (max-width: $breakpoint-tablet-large) {
    .addUserModalBox{
        .addUserBox{
            width: 50%;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet-medium) {
    .addUserModalBox{
        .addUserBox{
            width: 70%;
        }
    }
}

@media screen and (max-width: $breakpoint-extra-medium) {
    .addUserModalBox{
        .addUserBox{
            width: 95%;
        }
    }
}

@media screen and (max-width:380px){
    .addUserModalBox{
        .addUserBox{
            &::before{
                left: 3px;
            }
            .addUserBtnBox{
                button{
                    margin: 0 5px;
                    padding: 8px 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 320px){
    .addUserModalBox{
        .addUserBox{
            .addUserBtnBox{
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
                button{
                    padding: 5px 10px;
                    font-size: 13px;
                }
            }
        }
    }
}