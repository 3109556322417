.customersList {
  margin-top: 100px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
  margin-bottom: 120px;
}

.customersList .customersListHeadOne {
  display: none;
}

.customersList .innerCusListBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.customersList .innerCusListBox .btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.customersList .innerCusListBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}

.customersList .innerCusListBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}

.customersList .innerCusListBox .customersListHeadTwo {
  text-transform: uppercase;
  font-size: 30px;
}

.customersList .innerCusListBox .searchBar {
  position: relative;
  margin-right: 10px;
}

.customersList .innerCusListBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}

.customersList .innerCusListBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}

.customersList .innerCusListBox .searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}

.customersList .innerCusListBox .searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}

.customerListTag {
  white-space: break-spaces;
  line-height: 19px;
  display: block;
}

.customerMakeDiscountModal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.337254902);
}

.customerMakeDiscountModal .customerMakeDisInnerModalBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  position: relative;
  padding: 0px 20px;
  box-shadow: 5px 1px 10px grey;
  border-radius: 10px;
  border: 5px solid white;
  width: 50%;
}

.customerMakeDiscountModal .customerMakeDisInnerModalBox .closeIco {
  position: absolute;
  right: 2%;
  top: 7px;
  color: white;
  border: 2px solid white;
  font-size: 23px;
  padding: 2px;
  border-radius: 20px;
}

.customerMakeDiscountModal .customerMakeDisInnerModalBox h4 {
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  color: white;
  margin: 20px 0;
}

.customerMakeDiscountModal .customerMakeDisInnerModalBox .cusDisBtnBox {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.customerMakeDiscountModal .customerMakeDisInnerModalBox .cusDisBtnBox button {
  background: white;
  display: block;
  margin: 11px auto 0 auto;
  padding: 8px 18px;
  border-radius: 20px;
  font-weight: 500;
}

@media screen and (max-width: 992px) {
  .customersList .customersListHeadOne {
    display: block;
    font-size: 23px;
    margin: 15px 0 0 6px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
  }

  .customersList .innerCusListBox {
    justify-content: center;
  }

  .customersList .innerCusListBox .customersListHeadTwo {
    display: none;
  }

  .customersList .innerCusListBox .searchBar {
    margin-top: 10px;
    width: 90%;
    margin-right: 0;
  }

  .customersList .innerCusListBox .searchBar input {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .customerMakeDiscountModal .customerMakeDisInnerModalBox {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .customerMakeDiscountModal .customerMakeDisInnerModalBox {
    width: 95%;
  }
}

@media screen and (max-width: 420px) {
  .customersList .innerCusListBox .btnBox button {
    margin: 10px 5px;
  }

  .customersList .innerCusListBox .btnBox a {
    margin: 10px 5px;
  }
}

@media (max-width: 320px) {
  .customerMakeDiscountModal .customerMakeDisInnerModalBox h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 380px) {
  .customersList {
    margin-bottom: 200px !important;
  }
}

/*# sourceMappingURL=customersList.css.map */




.AddManualFrontsale button {

  background-color: rgba(0, 128, 0, 0.425);
}

.AddManualUpsell button {
  background-color: #1fd1f967;
}