// COLORS ===========================================
@import 'colors';
$color_1: #000;
$color_2: #333;
$color_3: #888;
$color_4: #00A9EF;
$color_5: #fff;
$background-color_1: #fff;
$background-color_2: #00A9EF;

.contactForm{
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.form-container {
	background-color: $background-color_1;
	padding: 30px;
	border-radius: 20px;
	box-shadow: 0 0 25px -15px rgba(0, 0, 0, 0.3);
	position: relative;
	&::before{
		content: "";
		position: absolute;
		height: 96%;
		width: 98%;
		top: 7px;
		left: 5px;
		border: 3px solid lightgray;
		padding: 42px 22px;
		border-radius: 11px;
	}
	.title {
		color: $color_1;
		font-size: 25px;
		font-weight: 600;
		text-transform: capitalize;
		margin: 0 0 25px;
		&:after {
			content: '';
			background-color: $background-color_2;
			height: 3px;
			width: 60px;
			margin: 10px 0 0;
			display: block;
			clear: both;
		}
	}
	form {
		position: relative;
		.form-group {
			color: $color_2;
			width: 100%;
			padding: 0 8px;
			margin: 0 0 15px;
			display: inline-block;
			&:nth-child(4) {
				margin-bottom: 30px;
			}
			label {
				font-size: 17px;
				font-weight: 600;
			}
		}
		.form-control {
			color: $color_3;
			background: #fff;
			font-weight: 400;
			letter-spacing: 1px;
			height: 40px;
			padding: 6px 12px;
			border-radius: 10px;
			border: 2px solid #e7e7e7;
		}
		.check-terms {
			padding: 0 8px;
			margin: 0 0 25px;
			.check-label {
				color: $color_2;
				font-size: 14px;
				font-weight: 500;
				font-style: italic;
				vertical-align: top;
				display: inline-block;
			}
			.checkbox {
				height: 17px;
				width: 17px;
				min-height: auto;
				margin: 2px 8px 0 0;
				border: 2px solid #d9d9d9;
				border-radius: 2px;
				cursor: pointer;
				display: inline-block;
				position: relative;
				transition: all 0.3s ease 0s;
			}
		}
		
		.contactBtn{
            color:#fff;
            text-transform: uppercase;
            padding: 10px 25px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition: all 0.3s ease;
        }
        .contactBtn:focus{ color: #fff; }
        .contactBtn:hover{
            color: #fff;
            background: transparent;
            text-shadow: 0 0 5px rgba(0,0,0,0.5);
        }
        .contactBtn:before{
            content: "";
            background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }
        button:hover:before{ 
            box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                        -4px -4px 6px 0 rgba(116, 125, 136, .2), 
                        inset -4px -4px 6px 0 rgba(255,255,255,.5),
                        inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
            transform: scale(2) rotate(180deg);
        }
	}
}
@media only screen and (max-width:480px) {
	.form-container {
		&::before{
			left: 3px;
		}
	}
}
