.topBarBox {
  background: white;
  box-shadow: 10px -5px 12px 6px rgba(158, 158, 158, 0.5098039216);
  padding: 10px 0;
  position: relative;
  width: -webkit-fill-available;
  z-index: 999;
}
.topBarBox span.barIco svg {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 26px;
  margin-left: 20px;
  position: relative;
  top: 7px;
}
.topBarBox .leftAreaBoxTopBar {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
}

.leftAreaBoxTopBar .massBox {
  position: relative;
}
.leftAreaBoxTopBar .massBox .massBoxIco {
  margin-left: 50px;
}
.leftAreaBoxTopBar .massBox .massBoxIco svg {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 30px;
  transition: all 1s;
}
.leftAreaBoxTopBar .massBox .massBoxIco svg:hover {
  color: #064CDB;
  transition: all 1s;
}
.leftAreaBoxTopBar .notifyBox {
  position: relative;
}
.leftAreaBoxTopBar .notifyBox .notifyBoxIco {
  margin-left: 30px;
  position: relative;
}
.leftAreaBoxTopBar .notifyBox .notifyBoxIco .notificateCount {
  position: relative;
  right: 8px;
  top: -14px;
  background: red;
  color: white;
  padding: 0px 5px;
  font-size: 10px;
  border-radius: 5px;
}
.leftAreaBoxTopBar .notifyBox .notifyBoxIco svg {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 30px;
  transition: all 1s;
}
.leftAreaBoxTopBar .notifyBox .notifyBoxIco svg:hover {
  color: #064CDB;
  transition: all 1s;
}

.notifyBox .notifyModal {
  position: absolute;
  width: 350px;
  box-shadow: 10px 2px 32px 6px rgba(76, 73, 73, 0.4);
  background: white;
  right: 0;
  margin-top: 40px;
  display: none;
}
.notifyBox .notifyModal .modalHeader {
  display: flex;
  justify-content: space-around;
  background: #ccced1;
  height: 70px;
  align-items: center;
  background-image: url(../images/ModalHeader.webp) !important;
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.3882352941);
}
.notifyBox .notifyModal .modalHeader h6.notifyCount {
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
}
.notifyBox .notifyModal .modalHeader button {
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
}
.notifyBox .notifyModal .notifyContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  box-shadow: 1px 2px 10px #cec8c8;
  margin: 7px 7px;
  border-radius: 9px;
}
.notifyBox .notifyModal .notifyContent img {
  border-radius: 50px;
  height: 45px;
}
.notifyBox .notifyModal .notifyContent .userContent {
  margin-left: 10px;
}
.notifyBox .notifyModal .notifyContent .userContent .userName {
  font-weight: 500;
  font-size: 16px;
}
.notifyBox .notifyModal .notifyContent .userContent .userDescription {
  color: rgba(128, 128, 128, 0.3215686275);
  font-size: 12px;
  margin-top: 5px;
  letter-spacing: 0.1px;
}
.notifyBox .notifyModal .notifyFooter {
  background: white;
  border-top: 1px solid rgba(128, 128, 128, 0.3215686275);
  text-align: center;
  padding: 11px 0;
  margin-top: 10px;
}
.notifyBox .notifyModal .notifyFooter a {
  color: #064CDB;
}

#hideNotifyModal {
  display: none;
  z-index: -1;
  transition: all 0.1s;
}

#showNotifyModal {
  display: block;
  transition: all 1s;
  z-index: 9999;
}

.userProfileBox {
  position: relative;
}
.userProfileBox .userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  border-left: 1px solid rgba(128, 128, 128, 0.3215686275);
  padding-left: 9px;
}
.userProfileBox .userInfo h5 {
  display: flex;
  flex-direction: column;
  margin: 0 15px 0px 0px;
}
.userProfileBox .userInfo h5 span.userName {
  font-size: 15px;
  text-transform: capitalize;
}
.userProfileBox .userInfo h5 span.userDesig {
  color: rgba(128, 128, 128, 0.3215686275);
  margin-top: 5px;
  font-size: 13px;
}
.userProfileBox .userInfo .userActiveBox {
  position: relative;
}
.userProfileBox .userInfo .userActiveBox svg {
  position: absolute;
  color: #32b432;
  left: -13px;
  top: -3px;
  font-size: 30px;
}
.userProfileBox .userInfo .userActiveBox img {
  height: 50px;
  width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.userProfileBox .userProfileModal {
  position: absolute;
  width: 350px;
  right: 25px;
  margin-top: 32px;
  box-shadow: 10px 2px 32px 6px rgba(76, 73, 73, 0.4);
  background: white;
  display: none;
}
.userProfileBox .userProfileModal .profileHeader {
  height: 90px;
  background-image: url(../images/ModalHeader.webp) !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-blend-mode: overlay;
  background: rgba(0, 0, 0, 0.3882352941);
}
.userProfileBox .userProfileModal .profileHeader h5 {
  color: white;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.userProfileBox .userProfileModal .profileHeader h5 span {
  line-height: 22px;
}
.userProfileBox .userProfileModal .profileHeader h5 span.userName {
  font-size: 15px;
  text-transform: capitalize;
}
.userProfileBox .userProfileModal .profileHeader h5 span.emailUser {
  font-size: 13px;
}
.userProfileBox .userProfileModal .profileHeader h5 span.roleofUser {
  font-size: 15px;
  text-transform: capitalize;
}
.userProfileBox .userProfileModal .profileHeader svg {
  transform: rotate(90deg);
  color: white;
  font-size: 25px;
}
.userProfileBox .userProfileModal .profileContentBox .innerProfileContentBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  line-height: 3;
  transition: all 1s;
}
.userProfileBox .userProfileModal .profileContentBox .innerProfileContentBox:hover span {
  color: #064CDB;
  transition: all 1s;
}
.userProfileBox .userProfileModal .profileContentBox .innerProfileContentBox svg {
  margin-right: 5px;
  font-size: 20px;
}
.userProfileBox .userProfileModal .profileContentBox .innerProfileContentBox span {
  color: #a2a5bd;
}
.userProfileBox .userProfileModal #profileNotificateBox {
  display: flex;
  justify-content: space-between;
}
.userProfileBox .userProfileModal #profileNotificateBox .profileNotifyCounter {
  background: #2196f3;
  color: white;
  padding: 5px 10px;
  position: relative;
  right: 20px;
  line-height: normal;
  border-radius: 50px;
}

#hideProfile {
  display: none;
  z-index: -1;
  transition: all 0.1s;
}

#showProfile {
  display: block;
  z-index: 9999;
  transition: all 1s;
}

@media screen and (max-width: 992px) {
  .topBarBox span.barIco svg {
    margin-left: 6px;
    position: relative;
    top: 3px;
  }
}
@media (max-width: 768px) {
  .topBarBox .leftAreaBoxTopBar {
    position: unset;
  }
  .topBarBox .notifyBox {
    position: unset;
  }
  .topBarBox .notifyBox .notifyModal {
    margin-top: 20px;
    width: 100%;
  }
  .topBarBox .userProfileBox {
    position: unset;
  }
  .topBarBox .userProfileBox .userProfileModal {
    margin-top: 11px;
    width: 100%;
    right: 0;
  }
}
@media screen and (max-width: 480px) {
  .topBarBox .userProfileBox .userInfo {
    border: 0;
  }
  .topBarBox .userProfileBox .userInfo h5 {
    display: none;
  }
}
@media screen and (max-width: 310px) {
  .topBarBox .userProfileBox .userInfo {
    margin: 0 10px;
  }
  .topBarBox .notifyBox .notifyBoxIco {
    margin-left: 15px;
  }
}
@media screen and (max-width: 280px) {
  .leftAreaBoxTopBar .notifyBox .notifyBoxIco svg {
    font-size: 23px;
  }
  .userProfileBox .userInfo {
    margin: 0 10px 0 0px !important;
  }
  .userProfileBox .userInfo .userActiveBox svg {
    left: -6px;
    top: 1px;
    font-size: 15px;
  }
}/*# sourceMappingURL=topBar.css.map */