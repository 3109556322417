
// COLORS ===========================================
@import 'colors';


.client_loginFormSection{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.client_loginFormBox{
    .logoBox{
        background: $themeTxtColor;
        display: inline-block;
        padding: 10px 15px;
        border-radius: 15px;
        margin-bottom: 20px;
        img{
            object-fit: contain;
            height: 55px;
            padding: 10px 18px;
        }
    }
    .client_pwdBoxOfLogin{
        position: relative;
        img{
            position: absolute;
            height: 18px;
            width: 18px;
            right: 11px;
            top: 44px;
        }
    }
    .client_formRememberBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-check{
            label{
                font-weight: 600;
            color: $greyTextColor;

            }
        }
        a{
            color: $greyTextColor;
        }
    }
    .client_LoginLinkBox{
        padding: 0;
        margin-top: 10px;
        span{
            color: $lightgreycolor;
        }
    }
    .client_signInBtnBox{
        button{
            color:#fff;
            text-transform: uppercase;
            padding: 10px 25px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition: all 0.3s ease;
        }
        button:focus{ color: #fff; }
        button:hover{
            color: #fff;
            background: transparent;
            text-shadow: 0 0 5px rgba(0,0,0,0.5);
        }
        button:before{
            content: "";
            background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }
        button:hover:before{ 
            box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                        -4px -4px 6px 0 rgba(116, 125, 136, .2), 
                        inset -4px -4px 6px 0 rgba(255,255,255,.5),
                        inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
            transform: scale(2) rotate(180deg);
        }
        span{
            margin-top: 10px;
            font-weight: 500;
            color: $greyTextColor;
            a{
                margin-left: 5px;
                color: $greyTextColor;
                text-decoration: underline !important;
            }
        }
    }
}


// RESPONSIVE ===========================================================================
$breakpoint-large: 992px;
$breakpoint-extra-small: 420px;

@media screen and (max-width: $breakpoint-large){
    .client_formImgBox{
        display: none;
    }
}

@media screen and (max-width: $breakpoint-extra-small){
    .client_loginFormBox{
        .client_formRememberBox {
            align-items: self-start;
            justify-content: start;
            flex-direction: column;
            a{
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 300px){
    .client_loginFormSection{
        height: auto;
    }
    .client_loginFormBox{
        .logoBox{
            padding: 10px 10px;
            img{
                height: 45px;
                padding: 5px 10px;
            }
        }
    }
}