// COLORS ===========================================
@import 'colors';


.client_addOrderModalBox{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000056;
    top: 0;
    left: 0;
    z-index: 9999;

    .client_addOrderBox{
        width: 40%;
        background: white;
        padding: 20px 10px;
        border-radius: 15px;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 98%;
            width: 98%;
            top: 5px;
            left: 5px;
            border: 3px solid lightgray;
            padding: 42px 22px;
            border-radius: 11px;
        }
        h4{
            background: $themeTxtColor;
            padding: 10px 0;
            margin: 0 20px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            position: relative;
            img{
                height: 40px;
            }
        }
        .client_OrderScrollBox{
            overflow-y: scroll;
            height: 360px;
            padding: 0 15px;
            margin: 0 5px;
            position: relative;
             // SCROLL BOX SCSS 
             &::-webkit-scrollbar-track{
                background-color: white;
            }
            &::-webkit-scrollbar{
                width: 4px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: $themeTxtColor;
            }
        }
        .client_inneraddOrderBox{
            margin: 0 5px;
            label{
                margin-top: 15px;
                margin-bottom: 8px;
            }
            select{
                height: 37px;
                position: relative;
                option.client_defaultValueOptionTag{
                    font-weight: 700;
                    letter-spacing: 1px;
                }
            }
            input{
                width: 100%;
            }
        }
        .client_orderBtnBox{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            position: relative;
            button:nth-child(1){
                background: #fd5d93;
                color: $white;
            }
            button{
                margin: 0 10px;
                padding: 10px 25px;
                border-radius: 5px;
                font-weight: 500;
                letter-spacing: 1px;
            }
        }
    }
}

// RESPONSIVE ====================================================================
$breakpoint-tablet-large: 992px;
$breakpoint-tablet-medium: 768px;
$breakpoint-extra-medium: 480px;
@media screen and (max-width: $breakpoint-tablet-large) {
    .client_addOrderModalBox{
    .client_addOrderBox{
            width: 50% ;
    }}
}

@media screen and (max-width: $breakpoint-tablet-medium) {
    .client_addOrderModalBox{
    .client_addOrderBox{
            width: 70%;
    }}
}

@media screen and (max-width: $breakpoint-extra-medium) {
    .client_addOrderModalBox{
    .client_addOrderBox{
            width: 95%;
    }}
}


@media screen and (max-width:420px){
    .client_addOrderModalBox{
        .client_addOrderBox{
            .client_orderBtnBox{
                button{
                    margin: 0 5px;
                    padding: 10px 10px;
                    font-size: 16px;
                }
            }
        }
    }
}
@media screen and (max-width:320px){
    .client_addOrderModalBox{
        .client_addOrderBox{
            &::before{
                left: 3px;
            }
        }
    }
}