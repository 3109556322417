// COLORS ===========================================
@import 'colors';


.orderReplyMainBox {
    background: rgb(128 128 128 / 4%);
    padding: 20px 5px;
    margin-top: 100px;

    .orderReplyProfileBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .orderReplyProfileInnerBox {
            display: flex;
            align-items: center;

            img {
                border-radius: 50%;
            }

            h5 {
                display: flex;
                flex-direction: column;

                margin-left: 5px;

                span {
                    color: #607D8B;
                    font-size: 12px;
                }
            }
        }

        .orderReplyTimeBox{
            span{
                color: #607D8B;
                font-size: 14px;
            }
            span:nth-child(2){
                margin-left: 10px;
            }
        }

    }

    .orderReplyDesBox {
        background: white;
        position: relative;
        margin-top: 20px;
        border-radius: 15px;
        padding: 10px 20px;
        .orderReplyDesBody{
            span{
                border-bottom: 1px solid $borderColor;
                display: block;
                text-transform: capitalize;
                padding-bottom: 10px;
            }
            p{
                margin-top: 15px;
                word-break: break-all;
            }
        }
        .orderReplyBox{
            word-wrap: break-word;
            h6{
                font-size: 15px;
                color: grey;
            }
            li{
                font-size: 14px;
                line-height: 18px;
                margin-top: 10px;
                span{
                    display: none;
                    margin-left: 10px;
                    background: rgba(128, 128, 128, 0.459);
                    color: white;
                    padding: 3px 10px;
                    border-radius: 5px;
                }
                &:hover{
                    span{
                        display: inline-block;
                    }
                }
            }
        }
        .orderReplyBtnBox{
            margin-top: 50px;
            button{
                background: $themeTxtColor;
                border-radius: 10px;
                padding: 5px 30px;
                color: $white;
                &:hover{
                    background: $lightgreycolor;
                }
            }
        }
    }
}

.orderReplyBoxModal{
    position: fixed;
    background: white;
    width: 50%;
    right: 20px;
    bottom: 0;
    z-index: 9999;
    box-shadow: -4px -4px 10px #9e9e9e45;
    padding: 13px 13px;
    h5{
        border-bottom: 1px solid $lightgreycolor;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 18px;
        padding-bottom: 5px;
        color: grey;
    }
    textarea{
        height: 174px;
        width: 100%;
        border-color: $lightgreycolor;
        border-radius: 5px;
        padding-left: 10px;
        &:focus{
            outline: none;
        }
    }
    .orderreplyModalbtnBox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        button{
            background: $themeTxtColor;
            padding: 8px 30px;
            color: $white;
            border-radius: 10px;
            margin: 0 10px;
            text-transform: capitalize;
        }
    }
}

@media screen and (max-width: 768px){
    .orderReplyBoxModal{
        width: 70%;
    }
}
@media screen and (max-width: 575px){
    .orderReplyBoxModal{
        width: 80%;
        .orderreplyModalbtnBox{
            button{
                padding: 8px 10px;
                margin: 5px 10px;
            }
        }
    }
}