
// COLORS ===========================================
@import 'colors';


.forgotpwdForgotStep{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.forgotpwdStepsBox{
    .logoBox{
        background: $themeTxtColor;
        display: inline-block;
        padding: 10px 15px;
        border-radius: 15px;
        margin-bottom: 20px;
        img{
            object-fit: contain;
            height: 55px;
            padding: 10px 18px;
        }
    }
    .pwdSentBox{
        button{
            color:#fff;
            text-transform: uppercase;
            padding: 10px 25px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition: all 0.3s ease;
        }
        button:focus{ color: #fff; }
        button:hover{
            color: #fff;
            background: transparent;
            text-shadow: 0 0 5px rgba(0,0,0,0.5);
        }
        button:before{
            content: "";
            background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }
        button:hover:before{ 
            box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                        -4px -4px 6px 0 rgba(116, 125, 136, .2), 
                        inset -4px -4px 6px 0 rgba(255,255,255,.5),
                        inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
            transform: scale(2) rotate(180deg);
        }
    }
}

// =================================================
.otpSection{
    height: 100vh;
    display: flex;
    align-items: center;
    .otpImgBox{
        img{
            height: 122px;
            object-fit: contain;
            width: 100%;
            background: #2196F3;
            padding: 9px 0;
            margin: 40px 0 0 0;
            border-radius: 20px;
        }
    }
    .otpBox{
        .logoBox{
                background: $themeTxtColor;
                display: inline-block;
                padding: 10px 15px;
                border-radius: 15px;
                margin-bottom: 20px;
            img{
                object-fit: contain;
                height: 55px;
                padding: 10px 18px;
            }
        }
        label{
            font-size: 30px;
        }
        button{
            color:#fff;
            text-transform: uppercase;
            padding: 10px 25px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition: all 0.3s ease;
        }
        button:focus{ color: #fff; }
        button:hover{
            color: #fff;
            background: transparent;
            text-shadow: 0 0 5px rgba(0,0,0,0.5);
        }
        button:before{
            content: "";
            background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: all 0.3s ease 0s;
        }
        
        button:hover:before{ 
            box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                        -4px -4px 6px 0 rgba(116, 125, 136, .2), 
                        inset -4px -4px 6px 0 rgba(255,255,255,.5),
                        inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
            transform: scale(2) rotate(180deg);
        }
    }
}

// =================================================
.forgotpwdChangeStep{
    .forgotpwdChangeStepInnerBox{
        .logoBox{
                background: $themeTxtColor;
                display: inline-block;
                padding: 10px 15px;
                border-radius: 15px;
                margin-bottom: 20px;
            img{
                object-fit: contain;
                height: 55px;
                padding: 10px 18px;
            }
        }
        .form-group{
            position: relative;
            input{
                position: relative;
            }
            img{
                position: absolute;
                height: 20px;
                top: 41px;
                right: 10px;
            }
        }
        .pwdChangebtn{
            button{
                color:#fff;
                text-transform: uppercase;
                padding: 10px 25px;
                overflow: hidden;
                position: relative;
                z-index: 1;
                transition: all 0.3s ease;
            }
            button:focus{ color: #fff; }
            button:hover{
                color: #fff;
                background: transparent;
                text-shadow: 0 0 5px rgba(0,0,0,0.5);
            }
            button:before{
                content: "";
                background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transition: all 0.3s ease 0s;
            }
            
            button:hover:before{ 
                box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                            -4px -4px 6px 0 rgba(116, 125, 136, .2), 
                            inset -4px -4px 6px 0 rgba(255,255,255,.5),
                            inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
                transform: scale(2) rotate(180deg);
            }
        }
    }
}


// RESPONSIVE ===========================================================================
$breakpoint-large: 992px;
$breakpoint-extra-small: 420px;

@media screen and (max-width: $breakpoint-large){
    .formImgBox{
        display: none;
    }
}

@media screen and (max-width: 768px){
    .otpCol{
        max-width: unset !important;
        width: 100%;
        flex: unset !important;
    }
}

@media screen and (max-width: 300px){
    .forgotpwdStepsBox{
        .logoBox{
            padding: 10px 10px;
            img{
                height: 45px;
                padding: 5px 10px;
            }
        }
        .pwdSentBox{
            button{
                padding: 10px 10px;
                font-size: 12px;
            }
        }
    }
    .otpSection{
            .otpBox{
            .logoBox{
                padding: 10px 10px;
                img{
                    height: 45px;
                    padding: 5px 10px;
                }
            }
        }
    }
    .forgotpwdChangeStep{
        .forgotpwdChangeStepInnerBox{
            .logoBox{
                padding: 10px 10px;
                img{
                    height: 45px;
                    padding: 5px 10px;
                }
            }
        }
        .pwdChangebtn{
            button{
                padding: 10px 10px;
                font-size: 12px;
            }
        }
    }
}