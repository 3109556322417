.client_files {
  margin-top: 100px;
  margin-bottom: 120px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
}
.client_files h4 {
  font-size: 25px;
  text-transform: uppercase;
  margin: 20px 0 0 9px;
  font-weight: 500;
}
.client_files .client_innerFilesBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.client_files .client_innerFilesBox .client_btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.client_files .client_innerFilesBox .client_btnBox svg {
  font-size: 25px;
}
.client_files .client_innerFilesBox .client_btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.client_files .client_innerFilesBox .client_btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.client_files .client_innerFilesBox .client_searchBar {
  position: relative;
  margin: auto 10px;
  width: 100%;
}
.client_files .client_innerFilesBox .client_searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.client_files .client_innerFilesBox .client_searchBar input {
  letter-spacing: 1px;
  width: 100%;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.client_files .client_innerFilesBox .client_searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.client_files .client_innerFilesBox .client_searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.client_files .actionsBtns {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.client_files .actionsBtns button {
  padding: 4px 5px;
  color: white;
  border-radius: 5px;
  margin: 6px 6px;
}
.client_files .actionsBtns button svg {
  font-size: 20px;
}
.client_files .actionsBtns .upadteBtn {
  background: #00f2c3;
}
.client_files .actionsBtns .upadteBtn:hover {
  background: #10cea8;
}
.client_files .actionsBtns .insertBtn {
  background: #fd5d93;
}
.client_files .actionsBtns .insertBtn:hover {
  background: #df4478;
}

.client_insertModuleMainBox {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.281);
}
.client_insertModuleMainBox .client_innerInsertFileBox {
  background: white;
  border-radius: 20px;
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  border: 5px solid white;
  width: 30%;
  padding: 0 20px;
  position: relative;
}
.client_insertModuleMainBox .client_innerInsertFileBox h3 {
  position: absolute;
  right: 10px;
}
.client_insertModuleMainBox .client_innerInsertFileBox h3 svg {
  position: relative;
  font-size: 25px;
  color: white;
}
.client_insertModuleMainBox .client_innerInsertFileBox h5 {
  font-size: 25px;
  margin-top: 10px;
  color: white;
}
.client_insertModuleMainBox .client_innerInsertFileBox .client_fileInputBox {
  width: 100%;
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  border-radius: 20px;
  margin-top: 15px;
}
.client_insertModuleMainBox .client_innerInsertFileBox .client_fileInputBox input {
  opacity: 0;
  height: 150px;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
.client_insertModuleMainBox .client_innerInsertFileBox .client_fileInputBox .client_iconFileBox svg {
  font-size: 60px;
  display: block;
  margin: auto;
}
.client_insertModuleMainBox .client_innerInsertFileBox .client_fileInputBox .client_iconFileBox span {
  font-size: 17px;
  color: grey;
  margin-top: 15px;
  font-weight: 400;
}
.client_insertModuleMainBox .client_innerInsertFileBox button {
  background: white;
  color: black;
  margin: 12px auto 10px auto;
  display: block;
  padding: 5px 23px;
  border-radius: 30px;
  font-weight: 600;
}

.client_filesViewBox {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.281);
}
.client_filesViewBox .client_innerfilesViewBox {
  background: white;
  border-radius: 20px;
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  border: 5px solid white;
  width: 30%;
  padding: 0 20px;
  position: relative;
}
.client_filesViewBox .client_innerfilesViewBox h3 {
  position: absolute;
  right: 10px;
}
.client_filesViewBox .client_innerfilesViewBox h3 svg {
  position: relative;
  font-size: 25px;
  color: white;
}
.client_filesViewBox .client_innerfilesViewBox h5 {
  font-size: 25px;
  margin-top: 10px;
  color: white;
}
.client_filesViewBox .client_innerfilesViewBox > div {
  overflow-y: scroll;
  height: 220px;
  padding: 0 5px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.client_filesViewBox .client_innerfilesViewBox > div::-webkit-scrollbar {
  width: 4px;
}
.client_filesViewBox .client_innerfilesViewBox > div::-webkit-scrollbar-thumb {
  background-color: #064CDB;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems {
  background: white;
  box-shadow: 3px 9px 10px rgba(128, 128, 128, 0.6);
  padding: 7px 5px;
  border-radius: 10px;
  margin: 10px 0;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileFlex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileFlex img {
  height: 40px;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileFlex > svg {
  font-size: 40px;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileFlex span {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600;
  color: grey;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileFlex h5 {
  background: rgba(128, 128, 128, 0.3215686275);
  padding: 10px;
  line-height: 0;
  border-radius: 50%;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileFlex h5 svg {
  font-size: 24px;
  color: black;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileEditInputBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileEditInputBox input {
  height: 30px;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100px;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileEditInputBox input[type=file]::file-selector-button {
  border: none;
  background: #084cdf;
  color: white;
  border-radius: 5px;
  text-align: center;
}
.client_filesViewBox .client_innerfilesViewBox > div .client_fileItems .client_fileEditInputBox button {
  display: block;
  margin: 0 10px;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border-radius: 15px;
}

@media screen and (max-width: 992px) {
  .client_filesViewBox .client_innerfilesViewBox {
    width: 50%;
  }
  .client_insertModuleMainBox .client_innerInsertFileBox {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .client_filesViewBox .client_innerfilesViewBox {
    width: 70%;
  }
}
@media screen and (max-width: 575px) {
  .client_insertModuleMainBox .client_innerInsertFileBox {
    width: 70%;
  }
}
@media screen and (max-width: 420px) {
  .client_files .client_innerFilesBox .client_btnBox button {
    margin: 10px 5px;
  }
  .client_files .client_innerFilesBox .client_btnBox a {
    margin: 10px 5px;
  }
  .client_filesViewBox .client_innerfilesViewBox {
    width: 95%;
  }
  .client_insertModuleMainBox .client_innerInsertFileBox {
    width: 95%;
  }
}
@media screen and (max-width: 380px) {
  .client_files {
    margin-bottom: 200px !important;
  }
}/*# sourceMappingURL=clientFiles.css.map */