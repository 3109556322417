
// COLORS ===========================================
@import 'colors';


// CUSTOMER'S LEADS SCSS  ========================================================

.client_files{
    margin-top: 100px;
    margin-bottom: 120px;
    box-shadow: 3px 6px 10px $shadowColor;
    border-radius: 18px;
    border: 2px solid $borderColor;
    h4{
        font-size: 25px;
        text-transform: uppercase;
        margin: 20px 0 0 9px;
        font-weight: 500;
    }



    .client_innerFilesBox{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        border-bottom: 2px solid $borderColor;
        padding-bottom: 10px;
        margin-bottom: 20px;
        .client_btnBox{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            svg{
                font-size: 25px;
            }
            button{
                padding: 5px 10px;
                margin: 0 5px;
                height: 35px;
                border-radius: 5px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
            a{
                border-radius: 5px;
                margin: 0 5px;
                padding: 5px 10px;
                height: 35px;
                background: $lightBlue;
                color: $white;
                letter-spacing: 1px;
            }
        }
        .client_searchBar {
            position: relative;
            margin: auto 10px;
            width: 100%;
            svg {
                position: absolute;
                left: 6px;
                top: 12px;
                font-size: 22px;
                color: $lightgreycolor;
            }
            input {
                letter-spacing: 1px;
                width: 100%;
                border: none;
                background: $inputBg;
                border-radius: 5px;
                padding: 10px 17px 10px 30px;
                &::placeholder {
                    color: $greyTextColor;
                    font-size: 14px;
                }
            }
        }
    }
    .actionsBtns{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        button{
            padding: 4px 5px;
            color: white;
            border-radius: 5px;
            margin: 6px 6px;
            svg{
                font-size: 20px;
            }
        }
        .upadteBtn{
            background: #00f2c3;
            &:hover{
                background: #10cea8;
            }
        }
        .insertBtn{
            background: #fd5d93;
            &:hover{
                background: #df4478;
            }
        }
        
    }
}
.client_insertModuleMainBox{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.281);
    .client_innerInsertFileBox{
        background: white;
        border-radius: 20px;
        background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
        border: 5px solid white;
        width: 30%;
        // height: 300px;
        padding: 0 20px;
        position: relative;
        h3{
            position: absolute;
            right: 10px;
            svg{
                position: relative;
                font-size: 25px;
                color: $white;
            }
        }
        h5{
            font-size: 25px;
            margin-top: 10px;
            color: $white;
        }
        .client_fileInputBox{
            width: 100%;
            position: relative;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 180px;
            border-radius: 20px;
            margin-top: 15px;
            input{
                opacity: 0;
                height: 150px;
                position: absolute;
                width: 100%;
                cursor: pointer;
            }
            .client_iconFileBox{
                svg{
                    font-size: 60px;
                    display: block;
                    margin: auto;
                }
                span{
                    font-size: 17px;
                    color: grey;
                    margin-top: 15px;
                    font-weight: 400;
                }
            }
        }
        button{
            background: white;
            color: black;
            margin: 12px auto 10px auto;
            display: block;
            padding: 5px 23px;
            border-radius: 30px;
            font-weight: 600;
        }
    }
}

.client_filesViewBox{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.281);
    .client_innerfilesViewBox{
        background: white;
        border-radius: 20px;
        background: linear-gradient(315deg, #1fd1f9 0%, $themeTxtColor 74%);
        border: 5px solid white;
        width: 30%;
        // height: 300px;
        padding: 0 20px;
        position: relative;
        h3{
            position: absolute;
            right: 10px;
            svg{
                position: relative;
                font-size: 25px;
                color: $white;
            }
        }
        h5{
            font-size: 25px;
            margin-top: 10px;
            color: $white;
        }
        > div{
            overflow-y: scroll;
            height: 220px;
            // SCROLL BOX SCSS 
            padding: 0 5px;
            margin-top: 40px;
            margin-bottom: 20px;
            &::-webkit-scrollbar{
                width: 4px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: $themeTxtColor;
            }

            .client_fileItems{
                background: $white;
                box-shadow: 3px 9px 10px #80808099;
                padding: 7px 5px;
                border-radius: 10px;
                margin: 10px  0;
                .client_fileFlex{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    img{
                        height: 40px;
                    }
                    > svg{
                        font-size: 40px
                    }
                    span{
                        font-size: 15px;
                        letter-spacing: 1px;
                        font-weight: 600;
                        color: grey;
                    }
                    h5{
                        background: $lightgreycolor;
                        padding: 10px;
                        line-height: 0;
                        border-radius: 50%;
                        svg{
                            font-size: 24px;
                            color: black;
                        }
                    }
                }
                .client_fileEditInputBox{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    input{
                        height: 30px;
                        cursor: pointer;
                        appearance: none;
                        width: 100px;
                    }
                    input[type=file]::file-selector-button{
                        border: none;
                        background: #084cdf;
                        color: white;
                        border-radius: 5px;
                        text-align: center;
                    }
                    button{
                        display: block;
                        margin: 0 10px;
                        font-size: 14px;
                        padding: 5px 20px;
                        font-weight: 500;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}
// RESPONSIVE ====================================================================
$breakpoint-tablet: 768px;

@media screen and (max-width:992px){
    .client_filesViewBox{
        .client_innerfilesViewBox{
            width: 50%;
        }
    }
    .client_insertModuleMainBox{
        .client_innerInsertFileBox{
            width: 50%;
        }
    }
}
@media screen and (max-width: $breakpoint-tablet) {
    .client_filesViewBox{
        .client_innerfilesViewBox{
            width: 70%;
        }
    }
}
@media screen and (max-width:575px){
    .client_insertModuleMainBox{
        .client_innerInsertFileBox{
            width: 70%;
        }
    }
}
@media screen and (max-width:420px){
    .client_files{
        .client_innerFilesBox{
            .client_btnBox{
                button{
                    margin: 10px 5px;
                }
                a{
                    margin: 10px 5px;
                }
            }
        }
    }
    .client_filesViewBox{
        .client_innerfilesViewBox{
            width: 95%;
        }
    }
    .client_insertModuleMainBox{
        .client_innerInsertFileBox{
            width: 95%;
        }
    }
}
@media screen and (max-width: 380px){
    .client_files{
        margin-bottom: 200px !important
    }
}