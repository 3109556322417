
// COLOR CODE'S ==========================================================
@import 'colors';


// SEARCH BAR SCSS =========================================================
.topBarBox {
	background: $white;
	box-shadow: 10px -5px 12px 6px $shadowColor;
	padding: 10px 0;
    position: relative;
    width: -webkit-fill-available;
    z-index: 999;
	span.barIco {
		svg {
			color: $lightgreycolor;
			font-size: 26px;
			margin-left: 20px;
			position: relative;
			top: 7px;
		}
	}
	.leftAreaBoxTopBar {
		position: relative;
		float: right;
		display: flex;
		align-items: center;
		// .searchBar {
		// 	position: relative;
		// 	margin-right: 10px;
		// 	svg {
		// 		position: absolute;
		// 		left: 6px;
		// 		top: 12px;
		// 		font-size: 22px;
		// 		color: $lightgreycolor;
		// 	}
		// 	input {
		// 		letter-spacing: 1px;
		// 		border: none;
		// 		background: $inputBg;
		// 		border-radius: 5px;
		// 		padding: 10px 17px 10px 30px;
		// 		&::placeholder {
		// 			color: $grey;
		// 			font-size: 14px;
		// 		}
		// 	}
		// }
	}
}
.leftAreaBoxTopBar {
	.massBox {
		position: relative;
		.massBoxIco {
			margin-left: 50px;
			svg {
				color: $lightgreycolor;
				font-size: 30px;
				transition: all 1s;
				&:hover{
					color: $themeTxtColor;
					transition: all 1s;
				}
			}
		}
		
	}
	.notifyBox {
		position: relative;
		.notifyBoxIco {
			margin-left: 30px;
			position: relative;
			.notificateCount{
			    position: relative;
				right: 8px;
				top: -14px;
				background: red;
				color: white;
				padding: 0px 5px;
				font-size: 10px;
				border-radius: 5px;
			}
			
			svg {
				color: $lightgreycolor;
				font-size: 30px;
				transition: all 1s;
				&:hover{
					color: $themeTxtColor;
					transition: all 1s;
				}
			}
		}
	}
}


// NOTIFY BOX SCSS =========================================================
.notifyBox {
	.notifyModal {
		position: absolute;
		width: 350px;
		box-shadow: 10px 2px 32px 6px #4c494966;
		background: $white;
		right: 0;
		margin-top: 40px;
		display: none;
		.modalHeader {
			display: flex;
			justify-content: space-around;
			background: #ccced1;
			height: 70px;
			align-items: center;
			background-image: url(../images/ModalHeader.webp) !important;
			background-blend-mode: overlay;
			background: #00000063;
			h6.notifyCount {
				color: $white;
				font-size: 20px;
				letter-spacing: 1px;
			}
			button{
				border: none;
				padding: 7px 15px;
				border-radius: 5px;
			}
		}
		.notifyContent {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 10px;
			box-shadow: 1px 2px 10px #cec8c8;
			margin: 7px 7px;
			border-radius: 9px;
			img {
				border-radius: 50px;
				height: 45px;
			}
			.userContent {
				margin-left: 10px;
				.userName {
					font-weight: 500;
					font-size: 16px;
				}
				.userDescription {
					color: $lightgreycolor;
					font-size: 12px;
					margin-top: 5px;
					letter-spacing: 0.1px;
				}
			}
		}
		.notifyFooter{
			background: $white;
			border-top: 1px solid $borderColor;
			text-align: center;
			padding: 11px 0;
			margin-top: 10px;
			a{
				color: $themeTxtColor;
			}
		}
	}
}
#hideNotifyModal {
	display: none;
	z-index: -1;
	transition: all 0.1s;
}
#showNotifyModal {
	display: block;
	transition: all 1s;
	z-index: 9999;
}
// USER PROFILE BOX SCSS =========================================================
.userProfileBox{
	position: relative;
	.userInfo{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 20px;
		border-left: 1px solid $borderColor;
		padding-left: 9px;
		h5{
			display: flex;
			flex-direction: column;
			margin: 0 15px 0px 0px;
			span.userName{
				font-size: 15px;
				text-transform: capitalize;
			}
			span.userDesig{
				color: $lightgreycolor;
				margin-top: 5px;
				font-size: 13px;
			}
		}
		.userActiveBox{
			position: relative;
			svg{
				position: absolute;
				color: #32b432;
				left: -13px;
				top: -3px;
				font-size: 30px;
			}
			img{
				height: 50px;
				width: 50px;
				object-fit: cover;
				border-radius: 50%;
			}
		}
	}

	.userProfileModal{
		position: absolute;
		width: 350px;
		right: 25px;
		margin-top: 32px;
		box-shadow: 10px 2px 32px 6px #4c494966;
		background: $white;
		display: none;
		.profileHeader{
			height: 90px;
			background-image: url(../images/ModalHeader.webp) !important;
			display: flex;
			align-items: center;
			justify-content: space-around;
			background-blend-mode: overlay;
			background: #00000063;
			h5{
				color: $white;
				display: flex;
				flex-direction: column;
				margin: 0;
				span{
					line-height: 22px;
				}
				span.userName{
					font-size: 15px;
					text-transform: capitalize;
				}
				span.emailUser{
					font-size: 13px;
				}
				span.roleofUser{
					font-size: 15px;
					text-transform: capitalize;
				}
			}
			svg{
				transform: rotate(90deg);
				color: $white;
				font-size: 25px;
			}
		}
		.profileContentBox{
			.innerProfileContentBox{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-left: 20px;
				line-height: 3;
				transition: all 1s;
				&:hover{
					span{
						color: $themeTxtColor;
						transition: all 1s;
					}
				}
				svg{
					margin-right: 5px;
					font-size: 20px;
				}
				span{
					color: $txtGreyColor;
				}
			}
		}
		#profileNotificateBox{
			display: flex;
			justify-content: space-between;
			.profileNotifyCounter{
				background: #2196f3;
				color: white;
				padding: 5px 10px;
				position: relative;
				right: 20px;
				line-height: normal;
				border-radius: 50px;
			}
		}
	}
}
#hideProfile{
	display: none;
	z-index: -1;
	transition: all 0.1s;
}
#showProfile{
	display: block;
	z-index: 9999;
	transition: all 1s;
}


// RESPONSIVE ===============================================
$breakpoint-tablet: 768px;

@media screen and (max-width: 992px){
	.topBarBox{
		span.barIco{
			svg{
				margin-left: 6px;
				position: relative;
				top: 3px;
			}
		}
	}
}
@media (max-width: $breakpoint-tablet){
	.topBarBox  {
		.leftAreaBoxTopBar{
			position: unset;
		}
		.notifyBox{
			position: unset;
			.notifyModal{
				margin-top: 20px;
				width: 100%;
			}
		}
		.userProfileBox{
			position: unset;
			.userProfileModal{
				margin-top: 11px;
				width: 100%;
				right: 0;
			}
		}
	}
}


@media screen and (max-width: 480px){
	.topBarBox{
		.userProfileBox{
			.userInfo{
				border: 0;
				h5{
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 310px){
	.topBarBox{
		.userProfileBox{
			.userInfo{
				margin: 0 10px;
			}
		}
		.notifyBox{
			.notifyBoxIco{
				margin-left: 15px;
			}
		}
	}
}

@media screen and (max-width: 280px){
	.leftAreaBoxTopBar{
		.notifyBox{
			.notifyBoxIco{
				svg{
					font-size: 23px;
				}
			}
		}
	}
	.userProfileBox{
		.userInfo{
			margin: 0 10px 0 0px !important;
			.userActiveBox{
				svg{    
					left: -6px;
					top: 1px;
					font-size: 15px;
				}
				
			}
		}
	}
}