$greyTextColor: rgba(128, 128, 128, 0.658);
$lightgreycolor:  #80808052;
$borderColor: #80808052;
$white: white;
$shadowColor: #9e9e9e82;
$inputBg : #9e9e9e29;
$lightBlue: #2196f3;
$themeTxtColor: #064CDB;
$redColor:  red;
$txtGreyColor: #a2a5bd;
$bgGradientColor: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);