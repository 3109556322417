// COLORS ===========================================
@import 'colors';


.addLeadsModalBox{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(37, 36, 36, 0.397);
    top: 0;
    left: 0;
    z-index: 9999;

    .addLeadsBox{
        position: relative;
        width: 40%;
        background: white;
        padding: 20px 10px;
        border-radius: 15px;
        &::before{
            content: "";
            position: absolute;
            height: 98%;
            width: 98%;
            top: 5px;
            left: 5px;
            border: 3px solid lightgray;
            padding: 42px 22px;
            border-radius: 11px;
        }
        h4{
            background: $themeTxtColor;
            padding: 10px 0;
            border-radius: 10px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            img{
                height: 40px;
            }
        }
        .leadsScrollBox{
            overflow-y: scroll;
            height: 360px;
            padding: 0 15px;
            z-index: 9999;
            position: relative;
            &::-webkit-scrollbar-track{
                background-color: white;
            }
            &::-webkit-scrollbar{
                width: 4px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{
                background-color: $themeTxtColor;
            }
        }
        .inneraddLeadsBox{
            label{
                margin-top: 15px;
                margin-bottom: 8px;
            }
            select{
                height: 37px;
                position: relative;
                option.defaultValueOptionTag{
                    font-weight: 700;
                    letter-spacing: 1px;
                }
            }
            input{
                width: 100%;
            }
        }
        .addLeadsBtnBox{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            z-index: 9999;
            position: relative;
            button:nth-child(1){
                background: #fd5d93;
                color: $white;
            }
            button{
                margin: 0 10px;
                padding: 10px 25px;
                border-radius: 5px;
                font-weight: 500;
                letter-spacing: 1px;
            }
        }
    }
}

// RESPONSIVE ====================================================================
$breakpoint-tablet-large: 992px;
$breakpoint-tablet-medium: 768px;
$breakpoint-extra-medium: 480px;
@media screen and (max-width: $breakpoint-tablet-large) {
    .addLeadsModalBox{
        .addLeadsBox{
            width: 50%;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet-medium) {
    .addLeadsModalBox{
        .addLeadsBox{
            width: 70%;
        }
    }
}

@media screen and (max-width: $breakpoint-extra-medium) {
    .addLeadsModalBox{
        .addLeadsBox{
            width: 95%;
        }
    }
}

@media screen and (max-width:380px){
    .addLeadsModalBox{
        .addLeadsBox{
            &::before{
                left: 3px;
            }
        }
    }
}

@media screen and (max-width:320px){
    .addLeadsModalBox{
        .addLeadsBox{
            .addLeadsBtnBox{
                button{
                    margin: 0 5px;
                    padding: 8px 10px;
                    font-size: 15px;
                }
            }
        }
    }
}