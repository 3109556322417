.MainCalenderBox {
    height: 140px;
    border: 1px solid gainsboro;
    padding-block: 5px;
    padding-inline: 0px;
    transition: 0.3s all !important;
    user-select: none;
}

.MainCalenderBox * {
    transition: 0.3s all !important;
}

.MainCalenderBox p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
    color: cornflowerblue;
}

.MainCalenderBox:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
}

.MainCalenderBox h3 {
    font-weight: 600;
}

.TaskStrip_Transparent{
    left: 0px;
    height: 20px;
    background-color: rgb(224, 224, 224);
    transition: 0.5s all !important;
    display: flex;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    align-items: center;
    margin-bottom: 0px;
}
.TaskStrip {
    left: 0px;
    height: 20px;
    background-color: rgb(0, 193, 0);
    transition: 0.5s all !important;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}
.TaskStrip_Transparent p{
    padding-inline: 5px;
    color: black;
    margin: 0px;
}
.TaskStrip p {
    padding-inline: 5px;
    color: white;
    margin: 0px;
}

.CreateTaskModal {
    padding-top: 20px;
}
.CreateTaskModal h2{
    font-weight: 600;
}

.LoadTitle {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TaskStrip_detail {
    left: 0px;
    height: 30px;
    background-color: rgb(0, 193, 0);
    transition: 0.5s all !important;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    cursor: pointer;
}

.TaskStrip_detail p {
    padding-inline:15px;
    color: white;
    margin: 0px;
}

.SubmitBtn{
    padding-inline: 20px;
    padding-block: 7px;
}
.CreateTaskModal h3{
    font-weight: 600;
}
.descriptionDetail{
    font-size: 14px;
    color: rgb(167, 167, 167);
}
.ColortagDetail{
    height: 20px !important;
    width: 20px !important;margin-right: 10px;
    border: none;
    border-radius: 4px;
    margin-top: 5px;
}
.DetailPara{
    color: cornflowerblue;
    font-weight: 600;
}

.CreateTaskModal h1{
    font-weight: 600;
    color: black;
}
.ColorDot{
    height: 30px;
    width: 30px;
    border: none;
    border-radius:5px;
}
.CreateTaskModal button{
    padding-inline: 15px;
    padding-block: 5px;
    border-radius: 5px;
}
.MainCalenderBox button{
    background-color: transparent;
    padding: 0px 8px;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
}