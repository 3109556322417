.Client_forgotpwdForgotStep {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Client_forgotpwdStepsBox .logoBox {
  background: #064CDB;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.Client_forgotpwdStepsBox .logoBox img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 55px;
  padding: 10px 18px;
}
.Client_forgotpwdStepsBox .Client_pwdSentBox button {
  color: #fff;
  text-transform: uppercase;
  padding: 10px 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.Client_forgotpwdStepsBox .Client_pwdSentBox button:focus {
  color: #fff;
}
.Client_forgotpwdStepsBox .Client_pwdSentBox button:hover {
  color: #fff;
  background: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.Client_forgotpwdStepsBox .Client_pwdSentBox button:before {
  content: "";
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.Client_forgotpwdStepsBox .Client_pwdSentBox button:hover:before {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  transform: scale(2) rotate(180deg);
}

.Client_otpSection .Client_otpImgBox img {
  height: 180px;
  width: 100%;
  background: #2196F3;
  padding: 9px 0;
  margin: 40px 0 0 0;
  border-radius: 20px;
}
.Client_otpSection .Client_otpBox .logoBox {
  background: #064CDB;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.Client_otpSection .Client_otpBox .logoBox img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 55px;
  padding: 10px 18px;
}
.Client_otpSection .Client_otpBox label {
  font-size: 30px;
}
.Client_otpSection .Client_otpBox button {
  color: #fff;
  text-transform: uppercase;
  padding: 10px 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.Client_otpSection .Client_otpBox button:focus {
  color: #fff;
}
.Client_otpSection .Client_otpBox button:hover {
  color: #fff;
  background: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.Client_otpSection .Client_otpBox button:before {
  content: "";
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.Client_otpSection .Client_otpBox button:hover:before {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  transform: scale(2) rotate(180deg);
}

.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .logoBox {
  background: #064CDB;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .logoBox img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 55px;
  padding: 10px 18px;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .form-group {
  position: relative;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .form-group input {
  position: relative;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .form-group img {
  position: absolute;
  height: 20px;
  top: 42px;
  right: 10px;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .Client_pwdChangebtn button {
  color: #fff;
  text-transform: uppercase;
  padding: 10px 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .Client_pwdChangebtn button:focus {
  color: #fff;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .Client_pwdChangebtn button:hover {
  color: #fff;
  background: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .Client_pwdChangebtn button:before {
  content: "";
  background: linear-gradient(315deg, #1fd1f9 0%, #064CDB 74%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .Client_pwdChangebtn button:hover:before {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5), inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
  transform: scale(2) rotate(180deg);
}

@media screen and (max-width: 992px) {
  .Client_formImgBox {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .Client_otpCol {
    max-width: unset !important;
    width: 100%;
    flex: unset !important;
  }
}
@media screen and (max-width: 300px) {
  .Client_forgotpwdStepsBox .logoBox {
    padding: 10px 10px;
  }
  .Client_forgotpwdStepsBox .logoBox img {
    height: 45px;
    padding: 5px 10px;
  }
  .Client_forgotpwdStepsBox .Client_pwdSentBox button {
    padding: 10px 10px;
    font-size: 12px;
  }
  .Client_otpSection .Client_otpBox .logoBox {
    padding: 10px 10px;
  }
  .Client_otpSection .Client_otpBox .logoBox img {
    height: 45px;
    padding: 5px 10px;
  }
  .Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .logoBox {
    padding: 10px 10px;
  }
  .Client_forgotpwdChangeStep .Client_forgotpwdChangeStepInnerBox .logoBox img {
    height: 45px;
    padding: 5px 10px;
  }
  .Client_forgotpwdChangeStep .Client_pwdChangebtn button {
    padding: 10px 10px;
    font-size: 12px;
  }
}/*# sourceMappingURL=clientforgotPwdSteps.css.map */