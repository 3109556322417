// COLORS ===========================================
@import 'colors';

.chatFlexBox{
    position: relative;
    z-index: 99;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: 1px -3px 12px $shadowColor;
    padding: 10px 0px;
    background: $white;
    display: flex;
    .chatLeftSideBox{
        width: 30%;
        border-right: 1px solid $borderColor;
        .chatSearchBox{
            display: flex;
            flex-wrap: nowrap;
            padding: 10px 20px;
            margin-bottom: 30px;
            input{
                border: 1px solid $themeTxtColor;
                height: 50px;
                width: 100%;
                border-radius: 5px 0px 0 5px;
                padding: 20px;
                font-size: 15px;
                &::placeholder{
                    letter-spacing: 2px;
                }
            }
            svg{
                background: $themeTxtColor;
                color: $white;
                font-size: 55px;
                padding: 5px 10px;
                height: 50px;
                border-radius: 0px 5px 5px 0px;

            }
        }
        // SCROLL BOX SCSS 
        .chatBodyScrollBox::-webkit-scrollbar-track{
            background-color: white;
        }
        .chatBodyScrollBox::-webkit-scrollbar{
            width: 4px;
            background-color: #F5F5F5;
        }
        .chatBodyScrollBox::-webkit-scrollbar-thumb{
            background-color: $themeTxtColor;
        }
        .chatTxtBody{
            display: flex;
            justify-content: space-between;
            padding: 15px 12px;
            border-bottom: 1px solid $borderColor;
            cursor: pointer;
            .chatTxtInnerBody{
                display: flex;
                align-items: center;
            }
            &:hover{
                background: $themeTxtColor;
                transition: 1s;
                .chatContentBox{
                    span{
                        color: $white;
                    }
                    p{
                        color: $white;
                    }
                }
                .chatTimeLineAndNotificateBox{
                    span{
                        color: $white;
                    }
                    h4{
                        background: $white;
                        color: black;
                    }
                }
            }
            img{
                height: 47px;
                object-fit: cover;
                border-radius: 50%;
            }
            .chatContentBox{
                margin-left: 20px;
                span{
                    font-size: 14px;
                    font-weight: 600;
                }
                p{
                    font-size: 12px;
                    margin-top: 2px;
                    svg.msgSending{
                        font-size: 18px;
                        margin-right: 10px;
                    }
                    svg.hasSent{
                        font-size: 18px;
                        margin-right: 10px;
                    }
                }
            }
            .chatTimeLineAndNotificateBox{
                position: relative;
                span{
                    font-size: 10px;
                }
                h4{
                    width: 25px;
                    font-size: 12px;
                    background: $themeTxtColor;
                    padding: 5px 0px;
                    text-align: center;
                    border-radius: 3px;
                    color: $white;
                }
            }
        }


        // ACTIVE CHAT BOX SCSS
        #activeChatBox{
            background: $themeTxtColor;
            .chatContentBox{
                span{
                    color: $white;
                }
                p{
                    color: $white;
                }
            }
            .chatTimeLineAndNotificateBox{
                span{
                    color: $white;
                }
                h4{
                    background: $white;
                    color: black;
                }
            }
        }
    }
    .chatRightSideBox{
        width: 70%;
        .chatTopBar{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid $borderColor;
            .userOnlineStatus{
                h4{
                    font-size: 20px;
                    margin: 0;
                }
                span{
                    color: green;
                }
            }
            .chatSetting{
                position: relative;
                svg{
                    font-size: 25px;
                    cursor: pointer;
                }
                .chatActionsBox{
                    position: absolute;
                    width: 180px;
                    right: 0;
                    background: $white;
                    margin-top: 15px;
                    padding: 10px;
                    z-index: 9999;
                    box-shadow: 0px 1px 10px $shadowColor;
                    display: none;
                    ul{
                        li{
                            line-height: 50px;
                            svg{
                                font-size: 20px;
                                margin-right: 5px;
                            }
                            span{
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
        .chatRightScrollBox{
            height: 400px;
            overflow-y: scroll;
            background: #F8F8F8;

            // SCROLL BOX SCSS 
        &::-webkit-scrollbar-track{
            background: #F8F8F8;
        }
        &::-webkit-scrollbar{
            width: 4px;
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $themeTxtColor;
        }
        }
        .chatContentBoxRightOne{
            display: flex;
            margin-top: 20px;
            margin-left: 20px;
            img{
                height: 47px;
                object-fit: cover;
                border-radius: 50%;
            }
            p{
                position: relative;
                margin-left: 20px;
                padding: 5px 15px;
                width: 50%;
                border-radius: 10px 10px 10px 0;
                background: $white;
                .chatTimeBox{
                    float: right;
                    margin-top: 20px !important;
                    border: 1px solid $borderColor;
                    padding: 2px 7px;
                    border-radius: 10px;
                    span{
                        font-size: 12px;
                        font-weight: 500;
                    }
                    svg.signleCheck{
                        margin-left: 5px;
                    }
                    svg.Doublecheck_ico{
                        color: $lightBlue;
                        margin-left: 5px;
                    }
                }
            }
        }
        .chatContentBoxRightTwo{
            display: flex;
            margin-top: 20px;
            margin-right: 20px;
            justify-content: end;
            flex-direction: row-reverse;
            p{
                position: relative;
                margin-left: 20px;
                padding: 5px 15px;
                width: 50%;
                border-radius: 10px 10px 10px 0;
                background: $themeTxtColor;
                color: $white;
                .chatTimeBox{
                    float: right;
                    margin-top: 20px !important;
                    border: 1px solid $borderColor;
                    padding: 2px 7px;
                    border-radius: 10px;
                    span{
                        font-size: 12px;
                        font-weight: 500;
                    }
                    svg.signleCheck{
                        margin-left: 5px;
                    }
                    svg.Doublecheck_ico{
                        color: $lightBlue;
                        margin-left: 5px;
                    }
                }
            }
        }
        .inputChatbox{
            display: flex;
            flex-wrap: nowrap;
            padding: 20px 20px;
            background: $white;
            input{
                border: 1px solid $themeTxtColor;
                height: 50px;
                width: 100%;
                border-radius: 5px 0px 0 5px;
                padding: 20px;
                font-size: 15px;
                &::placeholder{
                    letter-spacing: 2px;
                }
            }
            svg{
                background: $themeTxtColor;
                color: $white;
                font-size: 55px;
                padding: 5px 10px;
                height: 50px;
                border-radius: 0px 5px 5px 0px;

            }
        }
    }
}
.ShowBox{
    display: block !important;
}


// RESPONSIVE ========================================================
$breakpoint-large: 992px;
$breakpoint-medium: 768px;


@media screen and (max-width: $breakpoint-large){
    .chatFlexBox{
        flex-direction: column;
        .chatLeftSideBox{
            width: auto;
        }
        .chatRightSideBox{
            width: auto;
        }
    }
}

@media screen and (max-width: $breakpoint-medium){
    .chatFlexBox{
        .chatRightSideBox{
            .chatContentBoxRightOne{
                p{
                    margin-right: 20px;
                    width: auto;
                }
            }
        }
    }
   
    .chatFlexBox{
        .chatRightSideBox{
            .chatContentBoxRightTwo{
                p{
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 420px){
    .chatFlexBox{
        .chatRightSideBox{
            .chatContentBoxRightOne{
                flex-direction: column;
                img{
                    width: 47px;
                    margin: 0 0 14px 18px;
                }
            }
        }
    }
}