.excelDataTableBox .btnBox button {
  background: #064CDB;
  color: white;
  border-radius: 5px;
  margin: 0 3px;
  padding: 4px 7px;
  margin-bottom: 5px;
}

.excelDataTableBox .btnBox button svg {
  color: white;
}

.modalFileExcelBox {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3215686275);
  top: 0;
}

.dataReportFileBox {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.dataReportFileBox form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 25px 0;
  background: #ededed;
  border-radius: 10px;
}

.dataReportFileBox h5 {
  margin-bottom: 30px;
  text-transform: capitalize;
  font-weight: 500;
}

.dataReportFileBox input {
  width: 80%;
  text-align: center;
  background: #c3c1c1;
  border-radius: 6px;
  padding: 5px 12px;
  cursor: pointer;
}

.dataReportFileBox button {
  background: #064CDB;
  margin-top: 14px;
  padding: 5px 25px;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 40px;
}

.dataReportFileBox svg {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 21px;
}/*# sourceMappingURL=dataReport.css.map */


.DoneIcon{
  color: rgba(43, 228, 43, 0.452);
  font-size: 24px;
}

.Pendingicon{
  font-size: 24px;
  color: rgba(201, 201, 55, 0.596);
}