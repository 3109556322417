.Avatar {
    width: 42px !important;
    border-radius: 50%;
    height: fit-content !important;
    background-color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UpperCard {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.UpperCard h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Avatar p {
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(255, 255, 255);
    font-size: 28px;
    margin: 0px;
}

.middleBody {
    padding-top: 7px;
}

.middleBody h6 {
    font-weight: 700;
}

.middleBody p {
    font-size: 12px;
    color: rgb(91, 91, 91);
}

.footer_body {
    display: flex;
    align-items: center;
    justify-content: end;
}

.PriorityHigh {
    background-color: red;
    padding-inline: 20px;
    color: white;
    padding-block: 4px;
    border-radius: 40px;
    font-size: 14px;
}

.PriorityMedium {
    background-color: rgb(0, 187, 255);
    padding-inline: 20px;
    color: white;
    padding-block: 4px;
    font-size: 14px;
    border-radius: 40px;
}

.PriorityLow {
    background-color: rgb(0, 255, 68);
    padding-inline: 20px;
    color: white;
    padding-block: 4px;
    border-radius: 40px;
    font-size: 14px;
}

.Projectname {
    background-color: teal;
    padding-inline: 20px;
    color: white;
    padding-block: 4px;
    border-radius: 40px;
    font-size: 14px;
}

.FileName {
    font-size: 12px;
    color: black;
    background-color: aliceblue;
    padding-block: 5px;
    padding-inline: 10px;
    border-radius: 20px;
}

.CreatedDate {
    font-size: 12px;
}

.Attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 30px;
}

.DetailModalBody {
    padding: 20px 10px;
}

.DetailModalBody h3 {
    font-weight: 600;
    font-size: 24px;
}

.AfterTitle p {
    font-weight: 600;
    color: rgb(139, 139, 139);
}

.AfterTitleText p {
    font-weight: 600;
    color: black;
}

.AfterTitleTextStatus p {
    font-weight: 600;
    background-color: rgb(38, 173, 49);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 2px;
    padding: 0px 15px;
}

.FileNameModal {
    padding-inline: 7px;
    padding-block: 4px;
    transition: 0.3s all;
    margin-top: 10px;
    background-color: transparent;
    border-radius: 20px;
}

.FileNameModal:hover {
    background-color: aliceblue;
}

.CommentInput {
    display: flex;
    align-items: center;
}

.CommentInput input {
    width: 100%;
    border: 1px solid rgb(212, 212, 212);
    padding-block: 8px;
    border-radius: 3px;
    padding-inline: 20px;
    background-color: #f7f7f8;
}

.CommentInput button {
    margin-left: 10px;
    padding-inline: 20px;
    padding-block: 8px;
    background-color: #2196f3;
    color: white;
    border-radius: 3px;
}

.CommentSection {
    margin-top: 15px;
    max-height: 300px;
    overflow-y: scroll;
    padding-inline: 10px;
}

.CommentSection::-webkit-scrollbar {
    width: 5px !important;
}

.CommentSection::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

.CommentSection::-webkit-scrollbar-thumb {
    background: #888 !important;
}

.CommentSection::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.CommentBox {
    border-radius: 3px;
    padding: 8px 8px 0px 8px;
}

.CommentBox h6 {
    font-weight: 600;
}

.CommentBox p {
    margin-bottom: 0px;
    font-size: 13px;
    color: rgb(150, 150, 150);
}

.CommentHeading {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

.AvatarComment {
    background-color: teal;
    color: white;
    font-size: 25px;
    font-weight: 600;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CommentHeading h6 {
    margin-bottom: 0px;
    margin-left: 10px;
}

.CommentHeading i {
    color: rgb(197, 197, 197);
    font-size: 9px;
    margin-inline: 5px;
}

.CommentAction {
    display: flex;
    align-items: center;
    padding-block: 10px;
}

.CommentAction button {
    background-color: transparent;
    border: 1px solid gainsboro;
    padding-inline: 10px;
    border-radius: 20px;
}

.RepluButton {
    color: black;
    padding-left: 8px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-left: 8px;
    border-left: 1px solid gainsboro;
    cursor: pointer;
}

.RepluButtonDelete {
    color: red;
    padding-left: 8px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-left: 8px;
    border-left: 1px solid gainsboro;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RepluButtonUpdate {
    color: rgb(37, 177, 37);
    padding-left: 8px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-left: 8px;
    border-left: 1px solid gainsboro;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
}

.UpdateCommentBox {
    border: 1px solid gainsboro;
    border-radius: 4px;
    outline: none;
    padding: 10px;
}

.MoreReplyBtn p {
    color: #2196f3;
    font-weight: 500;
    font-size: 14px;
    padding-left: 8px;
    font-family: 'Poppins', sans-serif;
    margin-left: 8px;
    border-left: 1px solid gainsboro;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AllReplies {
    margin: 15px 0px 15px 20px;
    padding-left: 10px;
    border-left: 1px solid gainsboro;
}

.AllReplies input {
    border: 1px solid gainsboro;
    border-radius: 4px;
    outline: none;
    width: auto;
    padding: 5px 10px 5px 10px;
}

.FileFormat {
    transition: 0.3s;
    padding-inline: 10px;
    padding-block: 5px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FileFormat:hover {
    background-color: gainsboro;
    color: white !important;
}

.DeleteFile{
    background-color: transparent;
    color: red;
    border: none;
    outline: none;
}
.FetchDownload{
    background-color: transparent;
    color: rgb(38, 173, 49) !important;
    border: none;
    outline: none;
}
.TaskUpdateBtn{
    color: white;
    background-color: #064CDB;
    padding-block: 8px;
    padding-inline: 20px;
    margin-top: 15px;
    border-radius: 10px;
}
.ListComponentHistory{
    display: flex;
    align-items: center;
    padding-block: 8px;
}
.RoundBallHistory{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 5px solid #064CDB;
}
.ListComponentHistory p{
    margin: 0px;
    padding-left: 10px;
}
.ListComponentHistory p span{
    font-weight: 600;
    margin: 0px;
    transition: 0.3s all;
}
.ListComponentHistory p span:hover{
    color: blue;
}