// COLORS ===========================================
@import 'colors';

.sideBarBox {
	background: #064CDB;
	padding-left: 10px;
	width: 220px;
	position: fixed;
	z-index: 9999;
	.logoBox {
		.barIco{
			position: relative;
		    display: block;
		    margin-bottom: 20px;
			display: none;
			svg{
				color: $white;
				position: absolute;
				right: 0;
				font-size: 29px;
				top: -16px;
			}
		}
		a {
			display: flex;
			justify-content: center;
			>img {
				height: 83px;
				width: 70%;
				object-fit: contain;
			}
		}
	}
	.menuBox {
		ul {
			li {
				display: flex;
				align-items: center;
				a {
					color: $white;
					text-transform: uppercase;
					letter-spacing: 2px;
					line-height: 39px;
					font-size: 11px;
					padding: 0 8px;
					width: -webkit-fill-available;
					margin-right: 10px;
					&:hover{
						color: $white;
					}
				}
			}
			svg {
				color: $white;
				font-size: 22px;
				margin-right: 20px;
			}
		}
	}
}

// SIDE BAR HIDE SHOW CLASSES ====================================================
.sideBarBox{
	height: 100vh;
	overflow-y: scroll;
	// overflow-x: hidden;
	position: fixed;
}
.scrollBoxSideBar{
	position: relative;
	background: #000;
}
.sideBarBox::-webkit-scrollbar-track {
	background-color: $themeTxtColor;
}
.sideBarBox::-webkit-scrollbar {
	width: 5px;
}
.sideBarBox::-webkit-scrollbar-thumb {
	background-color: #0ae;
	background-image: -webkit-gradient(linear,0 0,0 100%,color-stop(0.5, rgba(255, 255, 255, 0.2)),
	color-stop(0.5, transparent),to(transparent));
}
#sideBarShow{
	width: 220px;
}
#sideBarHide{
	width: 60px;
    overflow: unset !important;
	.menuBox{
		ul{
			li{
				justify-content: center;
				.sideBarLinksActive.active{
					background: transparent !important;
					svg{color: #1f1e1ee6;}
				}
				svg{
					font-size: 25px;
					margin-top: 10px;
					margin-bottom: 10px;
				}
				#hideTxt{
					display: none;
				}
			}
		}
	}
}

#scrollBoxSideBarShow{
	width: 250px;
}
#scrollBoxSideBarHide{
	width: 60px;
}
#showLogo{
	display: block;
}
#hideLogo{
	display: none;
}


// RESPONSIVE ==================================================
$breakpoint-large: 992px;
@media (max-width: $breakpoint-large) {
	.scrollBoxSideBar {
		position: absolute;
	}
	#sideBarHide {
		width: 0;
		left: -85%;
	}
	.logoBox{
		.barIco{
			display: block !important;
		}
	}
	#scrollBoxSideBarHide {
		width: 0;
		left: -85%;
		.logoBox{
			.barIco{
				svg{
					display: none;
				}
			}
		}
	}	
}