.Input{
    border:1px solid rgb(225 225 225);
    outline: none;
    /* width: 250px; */
    border-radius: 5px;
    padding: 7px;
    background-color: white;
    font-family: 'Poppins', sans-serif !important;
}
.Input::placeholder{
    font-size: 14px;
    font-family: 'Poppins', sans-serif !important;
}
.Label{
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px !important;
    margin: 0px;
    letter-spacing: 0.5px !important;
}
.Label input{
    width: 100%;
}
.Label p{
    font-weight: 400;
    margin: 5px 0px;
    font-size: 12px;
    color: red;
}