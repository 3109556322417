
.filterMainBox {
    position: absolute;
    right: 0;
    width: 300px;
    background: white;
    padding: 24px 15px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    z-index: 999;
    box-shadow: 0px 4px 10px grey;
    border-radius: 5px;
    h6{
        font-weight: 400;
        border-bottom: 1px solid lightgray;
        padding-bottom: 5px;
    }
    .filterCheckBox{
        display: flex;
        .form-check{
            margin: 0 5px;
        }
    }
}

@media screen and (max-width: 992px){
    .filterMainBox{
        width: 100%;
    }
}