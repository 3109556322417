.client_disputeOrders {
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
}
.client_disputeOrders .client_innerDisputeOrdersBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar {
  position: relative;
  margin-right: 10px;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}

@media (max-width: 768px) {
  .client_disputeOrders .client_innerDisputeOrdersBox {
    justify-content: center;
  }
  .client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar {
    margin-top: 20px;
    width: 90%;
    margin-right: 0;
  }
  .client_disputeOrders .client_innerDisputeOrdersBox .client_searchBar input {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .client_disputeOrders .client_innerDisputeOrdersBox .client_btnBox button {
    margin: 10px 5px;
  }
  .client_disputeOrders .client_innerDisputeOrdersBox .client_btnBox a {
    margin: 10px 5px;
  }
}/*# sourceMappingURL=ClientDisputeOrder.css.map */