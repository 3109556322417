// COLORS ===========================================
@import 'colors';



.client_orderReplyMainBox {
    background: $lightgreycolor;
    padding: 20px 5px;
    margin-top: 100px;
    margin-bottom: 120px;

    .client_orderReplyProfileBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .client_orderReplyProfileInnerBox {
            display: flex;
            align-items: center;

            img {
                border-radius: 50%;
            }

            h5 {
                display: flex;
                flex-direction: column;

                margin-left: 5px;

                span {
                    color: #607D8B;
                    font-size: 12px;
                }
            }
        }

        .client_orderReplyTimeBox{
            span{
                color: #607D8B;
                font-size: 14px;
            }
            span:nth-child(2){
                margin-left: 10px;
            }
        }

    }

    .client_orderReplyDesBox {
        background: white;
        position: relative;
        margin-top: 20px;
        border-radius: 15px;
        padding: 10px 20px;
        .client_orderReplyDesBody{
            span{
                border-bottom: 1px solid $borderColor;
                display: block;
                text-transform: capitalize;
                padding-bottom: 10px;
            }
            p{
                margin-top: 15px;
                word-break: break-all;
            }
        }
        .client_orderReplyBox{
            word-wrap: break-word;
            h6{
                font-size: 15px;
                color: grey;
            }
            li{
                font-size: 14px;
                line-height: 18px;
                margin-top: 10px;
                span{
                    display: none;
                    margin-left: 10px;
                    background: rgba(128, 128, 128, 0.459);
                    color: white;
                    padding: 3px 10px;
                    border-radius: 5px;
                }
                &:hover{
                    span{
                        display: inline-block;
                    }
                }
            }
        }
        .client_orderReplyBtnBox{
            margin-top: 50px;
            button{
                background: $themeTxtColor;
                border-radius: 10px;
                padding: 5px 30px;
                color: $white;
            }
        }
    }
}

// =====================================
.client_orderReplyBoxModal{
    position: fixed;
    background: white;
    width: 50%;
    right: 20px;
    bottom: 24px;
    z-index: 9999;
    box-shadow: -4px -4px 10px #9e9e9e45;
    padding: 13px 13px;
    h5{
        border-bottom: 1px solid $lightgreycolor;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 18px;
        padding-bottom: 5px;
        color: grey;
    }
    textarea{
        height: 174px;
        width: 100%;
        border-color: $lightgreycolor;
        border-radius: 5px;
        padding-left: 10px;
        &:focus{
            outline: none;
        }
    }
    .client_orderreplyModalbtnBox{
        display: flex;
        margin-bottom: 20px;
        button{
            background: $themeTxtColor;
            padding: 8px 30px;
            color: $white;
            border-radius: 10px;
            margin: 0 10px;
            text-transform: capitalize;
        }
    }
}
@media screen and (max-width: 380px){
    .client_orderReplyMainBox {
        margin-bottom: 230px !important;
        .client_orderReplyProfileBox {
            .client_orderReplyProfileInnerBox {
                h5 {
                    span {
                        word-break: break-all;
                    }
                }
            }
    
        }
    }
}