.client_registerFormSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.client_registerFormBox .client_registerFlexBox {
  display: flex;
  justify-content: space-between;
}
.client_registerFormBox .client_formRememberBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.client_registerFormBox .client_formRememberBox .form-check label {
  font-weight: 600;
  color: rgba(128, 128, 128, 0.658);
}
.client_registerFormBox .client_signInBtnBox button {
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 5px;
  transition: 1ms;
}
.client_registerFormBox .client_signInBtnBox button:hover {
  transition: 1ms;
  background: #2196f3;
  color: white;
}
.client_registerFormBox .client_signInBtnBox span {
  margin-top: 20px;
  font-weight: 500;
  color: rgba(128, 128, 128, 0.658);
}
.client_registerFormBox .client_signInBtnBox span a {
  margin-left: 5px;
  color: rgba(128, 128, 128, 0.658);
  text-decoration: underline !important;
}

@media screen and (max-width: 992px) {
  .client_registerFormSection {
    height: auto;
  }
  .client_registerformImgBox {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .client_registerFormBox .client_registerFlexBox {
    flex-direction: column;
  }
  .client_registerFormBox .client_registerFlexBox .form-group label {
    margin-top: 10px;
  }
}/*# sourceMappingURL=clientregister.css.map */