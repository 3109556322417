.client_chatFlexBox {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  box-shadow: 1px -3px 12px rgba(158, 158, 158, 0.5098039216);
  padding: 10px 0px;
  background: white;
  display: flex;
}
.client_chatFlexBox .client_chatLeftSideBox {
  width: 30%;
  border-right: 1px solid rgba(128, 128, 128, 0.3215686275);
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatSearchBox {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 20px;
  margin-bottom: 30px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatSearchBox input {
  border: 1px solid #064CDB;
  height: 50px;
  width: 100%;
  border-radius: 5px 0px 0 5px;
  padding: 20px;
  font-size: 15px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatSearchBox input::-moz-placeholder {
  letter-spacing: 2px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatSearchBox input::placeholder {
  letter-spacing: 2px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatSearchBox svg {
  background: #064CDB;
  color: white;
  font-size: 55px;
  padding: 5px 10px;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatBodyScrollBox::-webkit-scrollbar-track {
  background-color: white;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatBodyScrollBox::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatBodyScrollBox::-webkit-scrollbar-thumb {
  background-color: #064CDB;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody {
  display: flex;
  justify-content: space-between;
  padding: 15px 12px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3215686275);
  cursor: pointer;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatTxtInnerBody {
  display: flex;
  align-items: center;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody:hover {
  background: #064CDB;
  transition: 1s;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody:hover .client_chatContentBox span {
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody:hover .client_chatContentBox p {
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody:hover .client_chatTimeLineAndNotificateBox span {
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody:hover .client_chatTimeLineAndNotificateBox h4 {
  background: white;
  color: black;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody img {
  height: 47px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatContentBox {
  margin-left: 20px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatContentBox span {
  font-size: 14px;
  font-weight: 600;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatContentBox p {
  font-size: 12px;
  margin-top: 2px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatContentBox p svg.client_msgSending {
  font-size: 18px;
  margin-right: 10px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatContentBox p svg.client_hasSent {
  font-size: 18px;
  margin-right: 10px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatTimeLineAndNotificateBox {
  position: relative;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatTimeLineAndNotificateBox span {
  font-size: 10px;
}
.client_chatFlexBox .client_chatLeftSideBox .client_chatTxtBody .client_chatTimeLineAndNotificateBox h4 {
  width: 25px;
  font-size: 12px;
  background: #064CDB;
  padding: 5px 0px;
  text-align: center;
  border-radius: 3px;
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox #client_activeChatBox {
  background: #064CDB;
}
.client_chatFlexBox .client_chatLeftSideBox #client_activeChatBox .client_chatContentBox span {
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox #client_activeChatBox .client_chatContentBox p {
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox #client_activeChatBox .client_chatTimeLineAndNotificateBox span {
  color: white;
}
.client_chatFlexBox .client_chatLeftSideBox #client_activeChatBox .client_chatTimeLineAndNotificateBox h4 {
  background: white;
  color: black;
}
.client_chatFlexBox .client_chatRightSideBox {
  width: 70%;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3215686275);
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_userOnlineStatus h4 {
  font-size: 20px;
  margin: 0;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_userOnlineStatus span {
  color: green;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_chatSetting {
  position: relative;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_chatSetting svg {
  font-size: 25px;
  cursor: pointer;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_chatSetting .client_chatActionsBox {
  position: absolute;
  width: 180px;
  right: 0;
  background: white;
  margin-top: 15px;
  padding: 10px;
  z-index: 9999;
  box-shadow: 0px 1px 10px rgba(158, 158, 158, 0.5098039216);
  display: none;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_chatSetting .client_chatActionsBox ul li {
  line-height: 50px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_chatSetting .client_chatActionsBox ul li svg {
  font-size: 20px;
  margin-right: 5px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatTopBar .client_chatSetting .client_chatActionsBox ul li span {
  font-weight: 400;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatRightScrollBox {
  height: 400px;
  overflow-y: scroll;
  background: #F8F8F8;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatRightScrollBox::-webkit-scrollbar-track {
  background: #F8F8F8;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatRightScrollBox::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatRightScrollBox::-webkit-scrollbar-thumb {
  background-color: #064CDB;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne img {
  height: 47px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne p {
  position: relative;
  margin-left: 20px;
  padding: 5px 15px;
  width: 50%;
  border-radius: 10px 10px 10px 0;
  background: white;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne p .client_chatTimeBox {
  float: right;
  margin-top: 20px !important;
  border: 1px solid rgba(128, 128, 128, 0.3215686275);
  padding: 2px 7px;
  border-radius: 10px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne p .client_chatTimeBox span {
  font-size: 12px;
  font-weight: 500;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne p .client_chatTimeBox svg.client_signleCheck {
  margin-left: 5px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne p .client_chatTimeBox svg.client_Doublecheck_ico {
  color: #2196f3;
  margin-left: 5px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo {
  display: flex;
  margin-top: 20px;
  margin-right: 20px;
  justify-content: end;
  flex-direction: row-reverse;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo p {
  position: relative;
  margin-left: 20px;
  padding: 5px 15px;
  width: 50%;
  border-radius: 10px 10px 10px 0;
  background: #064CDB;
  color: white;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo p .client_chatTimeBox {
  float: right;
  margin-top: 20px !important;
  border: 1px solid rgba(128, 128, 128, 0.3215686275);
  padding: 2px 7px;
  border-radius: 10px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo p .client_chatTimeBox span {
  font-size: 12px;
  font-weight: 500;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo p .client_chatTimeBox svg.client_signleCheck {
  margin-left: 5px;
}
.client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo p .client_chatTimeBox svg.client_Doublecheck_ico {
  color: #2196f3;
  margin-left: 5px;
}
.client_chatFlexBox .client_chatRightSideBox .client_inputChatbox {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 20px;
  background: white;
}
.client_chatFlexBox .client_chatRightSideBox .client_inputChatbox input {
  border: 1px solid #064CDB;
  height: 50px;
  width: 100%;
  border-radius: 5px 0px 0 5px;
  padding: 20px;
  font-size: 15px;
}
.client_chatFlexBox .client_chatRightSideBox .client_inputChatbox input::-moz-placeholder {
  letter-spacing: 2px;
}
.client_chatFlexBox .client_chatRightSideBox .client_inputChatbox input::placeholder {
  letter-spacing: 2px;
}
.client_chatFlexBox .client_chatRightSideBox .client_inputChatbox svg {
  background: #064CDB;
  color: white;
  font-size: 55px;
  padding: 5px 10px;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
}

.client_ShowBox {
  display: block !important;
}

@media screen and (max-width: 992px) {
  .client_chatFlexBox {
    flex-direction: column;
  }
  .client_chatFlexBox .client_chatLeftSideBox {
    width: auto;
  }
  .client_chatFlexBox .client_chatRightSideBox {
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne p {
    margin-right: 20px;
    width: auto;
  }
  .client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightTwo p {
    width: auto;
  }
}
@media screen and (max-width: 420px) {
  .client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne {
    flex-direction: column;
  }
  .client_chatFlexBox .client_chatRightSideBox .client_chatContentBoxRightOne img {
    width: 47px;
    margin: 0 0 14px 18px;
  }
}/*# sourceMappingURL=clientchat.css.map */