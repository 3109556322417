.MainHeaderSearch{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 30px;
}
.MainHeaderSearch h2{
    font-weight: 600;
    margin-bottom: 0px;
}

.MainHeaderSearch p{
    color: rgb(178, 178, 178);
}
.MainHeaderSearch input{
    border: 1px solid gainsboro;
    padding: 7px 20px;
    border-radius: 4px;
}
.tableShadow{
    padding: 0px 0px;
}
.MainHeaderSearch button{
    padding: 6px 15px;
    margin-left: 10px;
    border: 1px solid gainsboro;
    border-radius: 4px;
}