.webQuotBox {
  margin-top: 100px;
  border-radius: 18px;
  margin-bottom: 120px;
}
.webQuotBox .webQuotBoxOne {
  display: none;
}
.webQuotBox .innerWebQuotBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.webQuotBox .innerWebQuotBox .btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.webQuotBox .innerWebQuotBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.webQuotBox .innerWebQuotBox .btnBox svg {
  font-size: medium !important;
}
.webQuotBox .innerWebQuotBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.webQuotBox .innerWebQuotBox .webQuotBoxTwo {
  text-transform: uppercase;
  font-size: 30px;
}
.webQuotBox .innerWebQuotBox .searchBar {
  position: relative;
  margin-right: 10px;
}
.webQuotBox .innerWebQuotBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.webQuotBox .innerWebQuotBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.webQuotBox .innerWebQuotBox .searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.webQuotBox .innerWebQuotBox .searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.webQuotBox .actionsBtns {
  width: 100%;
}
.webQuotBox .actionsBtns button {
  padding: 10px 6px;
  margin: 5px 5px;
  color: white;
  border-radius: 5px;
}
.webQuotBox .actionsBtns .deleteBtn {
  background: #ec250d;
}

/* TIMER BOX CSS =-=-=-=-=-=-=-=-= */
.timerFlex {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 8px 10px;
  text-align: center;
  border-radius: 10px;
}

.timerFlex p {
  padding: 0;
  display: block;
  margin: 0;
  font-size: 25px;
}

.timerFlex b {
  border-bottom: 2px solid grey;
}

.timerBox {
  width: 40%;
  text-align: center;
}

.timerMainBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .webQuotBox .webQuotBoxOne {
    display: block;
    font-size: 23px;
    margin: 15px 0 0 6px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
  }
  .webQuotBox .innerWebQuotBox {
    justify-content: center !important;
  }
  .webQuotBox .innerWebQuotBox .webQuotBoxTwo {
    display: none;
  }
  .webQuotBox .innerWebQuotBox .searchBar {
    margin-top: 20px;
    width: 90%;
    margin-right: 0;
  }
  .webQuotBox .innerWebQuotBox .searchBar input {
    width: 100%;
  }
  .timerBox {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .timerBox {
    width: 90%;
  }
}
@media screen and (max-width: 480px) {
  .timerMainBox {
    justify-content: center;
  }
  .timerFlex {
    margin: 11px 10px;
  }
}
@media screen and (max-width: 420px) {
  .webQuotBox .innerWebQuotBox .btnBox button {
    margin: 10px 5px;
  }
  .webQuotBox .innerWebQuotBox .btnBox a {
    margin: 10px 5px;
  }
}
@media screen and (max-width: 380px) {
  .webQuotBox {
    margin-bottom: 200px !important;
  }
}/*# sourceMappingURL=websiteQuotations.css.map */