.ModalUpsell {
    background-color: white;
    position: absolute;
    top: 50px;
    width: 500px;
    left: calc(50% - 250px);
    right: 0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    min-height: 500px;
}

.backGrounD_container {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
}

.ManualUpsellBtnBox {
    display: flex;
    justify-content: end;
}

.MainUpsellgrid {
    position: absolute;
    top: 111px;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 10px;
    padding: 20px;

}

.Header {
    display: flex;
}

.Icon {
    font-size: 24px;
}