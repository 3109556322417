.CreateTaskHeader
{
    padding-inline: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CreateTaskHeader h2{
    font-weight: 600;
}
.CreateTaskHeader button{
    padding-inline: 30px;
    padding-block: 10px;
    background-color: #064CDB;
    color:white;
    border: 1px solid #064CDB;
    border-radius: 10px;
    transition: 0.3s all;
}
.CreateTaskHeader button:hover{
    padding-inline: 30px;
    padding-block: 10px;
    background-color: white;
    color:#064CDB;
    border: 1px solid #064CDB;
    border-radius: 10px;
}
.taskAddBtn{
    color: white;
    background-color: #064CDB;
    padding-block: 8px;
    padding-inline: 20px;
    margin-top: 15px;
    border-radius: 10px;
}