.userStatisticsBox {
  margin-top: 100px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
}
.userStatisticsBox .inneruserStatisticsBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.userStatisticsBox .inneruserStatisticsBox .btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.userStatisticsBox .inneruserStatisticsBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.userStatisticsBox .inneruserStatisticsBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.userStatisticsBox .inneruserStatisticsBox .searchBar {
  position: relative;
  margin-right: 10px;
}
.userStatisticsBox .inneruserStatisticsBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.userStatisticsBox .inneruserStatisticsBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.userStatisticsBox .inneruserStatisticsBox .searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.userStatisticsBox .inneruserStatisticsBox .searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .userStatisticsBox .inneruserStatisticsBox {
    justify-content: center;
  }
  .userStatisticsBox .inneruserStatisticsBox .searchBar {
    margin-top: 20px;
    width: 90%;
    margin-right: 0;
  }
  .userStatisticsBox .inneruserStatisticsBox .searchBar input {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .userStatisticsBox .inneruserStatisticsBox .btnBox button {
    margin: 10px 5px;
  }
  .userStatisticsBox .inneruserStatisticsBox .btnBox a {
    margin: 10px 5px;
  }
}/*# sourceMappingURL=userStatistics.css.map */