.allProfileAdminBox {
  margin-top: 100px;
  box-shadow: 3px 6px 10px rgba(158, 158, 158, 0.5098039216);
  border-radius: 18px;
  border: 2px solid rgba(128, 128, 128, 0.3215686275);
  margin-bottom: 120px;
}
.allProfileAdminBox h4.DesktopViewHead {
  font-size: 23px;
  margin: 15px 0 0 7px;
  font-weight: 600;
  text-transform: uppercase;
}
.allProfileAdminBox h4.MobileViewHead {
  display: none;
  font-size: 23px;
  margin: 15px 0 0 7px;
  font-weight: 600;
  text-transform: uppercase;
}
.allProfileAdminBox .innerallProfileAdminBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3215686275);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.allProfileAdminBox .innerallProfileAdminBox .btnBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.allProfileAdminBox .innerallProfileAdminBox .btnBox button {
  padding: 5px 10px;
  margin: 0 5px;
  height: 35px;
  border-radius: 5px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.allProfileAdminBox .innerallProfileAdminBox .btnBox button:hover {
  background-color: #1a83d8;
}
.allProfileAdminBox .innerallProfileAdminBox .btnBox a {
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  height: 35px;
  background: #2196f3;
  color: white;
  letter-spacing: 1px;
}
.allProfileAdminBox .innerallProfileAdminBox .searchBar {
  position: relative;
  margin-right: 10px;
}
.allProfileAdminBox .innerallProfileAdminBox .searchBar svg {
  position: absolute;
  left: 6px;
  top: 12px;
  font-size: 22px;
  color: rgba(128, 128, 128, 0.3215686275);
}
.allProfileAdminBox .innerallProfileAdminBox .searchBar input {
  letter-spacing: 1px;
  border: none;
  background: rgba(158, 158, 158, 0.1607843137);
  border-radius: 5px;
  padding: 10px 17px 10px 30px;
}
.allProfileAdminBox .innerallProfileAdminBox .searchBar input::-moz-placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}
.allProfileAdminBox .innerallProfileAdminBox .searchBar input::placeholder {
  color: rgba(128, 128, 128, 0.658);
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  .allProfileAdminBox h4.DesktopViewHead {
    display: none;
  }
  .allProfileAdminBox h4.MobileViewHead {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .allProfileAdminBox h4.MobileViewHead {
    text-align: center;
  }
  .allProfileAdminBox .innerallProfileAdminBox {
    justify-content: center;
  }
  .allProfileAdminBox .innerallProfileAdminBox .searchBar {
    width: 90%;
    margin-right: 0;
    margin: auto;
  }
  .allProfileAdminBox .innerallProfileAdminBox .searchBar input {
    margin-top: 5px;
    width: 100%;
  }
}
@media screen and (max-width: 380px) {
  .allProfileAdminBox {
    margin-bottom: 200px !important;
  }
}/*# sourceMappingURL=allProfiles.css.map */